import "core-js/modules/es.array.push.js";
import { computed, defineComponent, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'ObPaginator',
  emits: ['pageChange'],
  props: {
    pageTotal: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      required: true
    },
    page: {
      type: Number,
      required: true
    }
  },
  setup(props, {
    emit
  }) {
    const {
      t
    } = useI18n();
    const pagination = toRefs(props);
    const pages = computed(() => {
      return Math.ceil(pagination.pageTotal.value / pagination.pageSize.value);
    });
    const paginator = computed(() => {
      if (pages.value <= 3) {
        const pageNumbers = [];
        for (let i = 0; i < pages.value; i++) {
          pageNumbers.push(i + 1);
        }
        return {
          head: 0,
          pages: pageNumbers,
          end: 0
        };
      } else if (pagination.page.value >= 1 && pagination.page.value < 3) {
        return {
          head: 1,
          pages: [2, 3, '...'],
          end: pages.value
        };
      } else if (pagination.page.value >= 3 && pagination.page.value <= pages.value - 2) {
        return {
          head: 1,
          pages: ['...', pagination.page.value - 1, pagination.page.value, pagination.page.value + 1, '...'],
          end: pages.value
        };
      } else {
        return {
          head: 1,
          pages: ['...', pages.value - 2, pages.value - 1],
          end: pages.value
        };
      }
    });
    const pageChangeEmitter = page => {
      if (page === '...') return;
      emit('pageChange', page);
    };
    return {
      currentPage: computed(() => {
        return pagination.page.value;
      }),
      pageChangeEmitter,
      paginator,
      pages,
      t
    };
  }
});