import "core-js/modules/es.array.push.js";
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useAppStore } from '@/stores/app';
import { useI18n } from 'vue-i18n';
import { Dropdown, DropdownMenu, DropdownItem } from '@/components/Dropdown';
import { useRouter } from 'vue-router';
import { useNavigatorStore } from '@/stores/navigator';
import { useAuthorStore } from '@/stores/author';
import { AuthorPosts } from '@/models/Post.class';
import Social from '@/components/Social.vue';
export default defineComponent({
  name: 'ObMobileMenu',
  components: {
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Social
  },
  setup() {
    const appStore = useAppStore();
    const authorStore = useAuthorStore();
    const router = useRouter();
    const navigatorStore = useNavigatorStore();
    const {
      t
    } = useI18n();
    const authorData = ref(new AuthorPosts());
    const fetchAuthor = async () => {
      const author = appStore.themeConfig.site.author.toLocaleLowerCase();
      author.replace(/[\s]+/g, '-');
      await authorStore.fetchAuthorData(author).then(data => {
        authorData.value = data;
      });
    };
    const pushPage = path => {
      if (!path) return;
      navigatorStore.toggleMobileMenu();
      navigatorStore.setOpenNavigator(false);
      if (path.match(/(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g)) {
        window.location.href = path;
      } else {
        router.push({
          path: path
        });
      }
    };
    onMounted(fetchAuthor);
    return {
      themeConfig: computed(() => appStore.themeConfig),
      gradientBackground: computed(() => {
        return {
          background: appStore.themeConfig.theme.header_gradient_css
        };
      }),
      statistic: computed(() => appStore.statistic),
      routes: computed(() => appStore.themeConfig.menu.menus),
      authorData,
      pushPage,
      t
    };
  }
});