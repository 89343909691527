import "core-js/modules/es.array.push.js";
import { useSearchStore } from '@/stores/search';
import { computed, defineComponent, onBeforeMount, onMounted, onUnmounted, onUpdated, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
export default defineComponent({
  name: 'ObSearchModal',
  setup() {
    const searchStore = useSearchStore();
    const searchInput = ref();
    const searchIndexStatus = ref(false);
    const searchResults = ref([]);
    const router = useRouter();
    const openModal = ref(false);
    const openSearchContainer = ref(false);
    const keyword = ref('');
    const recentResults = ref();
    const menuActiveIndex = ref(0);
    const menuMaxIndex = ref(0);
    const isEmpty = ref(false);
    const {
      t
    } = useI18n();
    /**
     * Handlers
     */
    const handleStatusChange = status => {
      searchStore.setOpenModal(status);
    };
    const handleLinkClick = result => {
      searchStore.addRecentSearch(result);
      reloadRecentResult();
      handleStatusChange(false);
      if (result.slug !== '') router.push({
        name: 'post',
        params: {
          slug: result.slug
        }
      });
    };
    const handleResetInput = () => {
      keyword.value = '';
      searchResults.value = [];
      isEmpty.value = false;
      resetIndex(recentResults.value.length);
    };
    const handleArrowUp = () => {
      if (isEmpty.value === true) return;
      if (menuActiveIndex.value === 0) {
        menuActiveIndex.value = menuMaxIndex.value;
      } else {
        menuActiveIndex.value = menuActiveIndex.value - 1;
      }
      moveMenuWindow();
    };
    const handleArrowDown = () => {
      if (isEmpty.value === true) return;
      if (menuActiveIndex.value === menuMaxIndex.value) {
        menuActiveIndex.value = 0;
      } else {
        menuActiveIndex.value = menuActiveIndex.value + 1;
      }
      moveMenuWindow();
    };
    const moveMenuWindow = () => {
      const searchDropdownEl = document.getElementById('Search-Dropdown');
      const activeMenuEl = document.getElementById(`search-hit-item-${menuActiveIndex.value}`);
      const containerHeight = searchDropdownEl?.getBoundingClientRect().height;
      const menuElHeight = activeMenuEl?.getBoundingClientRect().height;
      if (menuElHeight && containerHeight && searchDropdownEl) {
        const currentMenuElheight = 36 + menuElHeight * (menuActiveIndex.value + 1);
        const heightDiff = currentMenuElheight - containerHeight;
        if (heightDiff > 0) {
          searchDropdownEl.scrollTo({
            top: heightDiff
          });
        }
      }
      if (searchDropdownEl && menuActiveIndex.value === 0) {
        searchDropdownEl.scrollTo({
          top: 0
        });
      }
    };
    const handleEnterDown = () => {
      if (searchResults.value.length === 0 && recentResults.value.length > 0) {
        handleLinkClick(recentResults.value[menuActiveIndex.value]);
      }
      if (searchResults.value.length > 0) {
        handleLinkClick(searchResults.value[menuActiveIndex.value]);
      }
    };
    /**
     * Search for records, debounce this function for 500ms
     * for user to finish typing. Prevent uncessary searches
     * between typing of a keyword.
     */
    const searchKeyword = _.debounce(e => {
      if (e.target.value !== '') {
        searchResults.value = searchStore.searchIndexes.search(e.target.value);
        if (searchResults.value.length > 0) {
          resetIndex(searchResults.value.length);
          isEmpty.value = false;
        } else {
          isEmpty.value = true;
        }
      } else {
        isEmpty.value = false;
        searchResults.value = [];
        resetIndex(recentResults.value.length);
      }
    }, 500);
    /** Refetch the data of recent search results. */
    const reloadRecentResult = () => {
      recentResults.value = searchStore.recentResults.getData();
      resetIndex(recentResults.value.length);
    };
    const resetIndex = max => {
      menuActiveIndex.value = 0;
      menuMaxIndex.value = max - 1;
    };
    /**
     * Initialize search modual default data.
     */
    const initSearch = async () => {
      searchIndexStatus.value = false;
      isEmpty.value = false;
      await searchStore.fetchSearchIndex().then(() => {
        searchIndexStatus.value = true;
      });
    };
    onBeforeMount(initSearch);
    onMounted(() => /** Delay focus for animation to finish. */
    setTimeout(() => {
      if (searchInput.value) searchInput.value.focus();
    }, 200));
    onUpdated(() => {
      /** Reset default values. */
      keyword.value = '';
      searchResults.value = [];
      /** Delay focus for animation to finish. */
      setTimeout(() => {
        if (searchInput.value) searchInput.value.focus();
      }, 200);
    });
    onUnmounted(() => {
      document.body.classList.remove('modal--active');
    });
    watch(() => searchStore.openModal, status => {
      /**
       * This watch is used to delay the animation
       * of the search box container.
       */
      if (status === true) reloadRecentResult();
      openModal.value = status;
      setTimeout(() => {
        openSearchContainer.value = status;
      }, 200);
    });
    return {
      openModal: computed(() => openModal.value),
      openSearchContainer: computed(() => openSearchContainer.value),
      searchResultsCount: computed(() => {
        return t('settings.search-result').replace('[total]', String(searchResults.value.length));
      }),
      handleStatusChange,
      handleLinkClick,
      searchInput,
      searchResults,
      keyword,
      isEmpty,
      searchKeyword,
      recentResults,
      handleResetInput,
      handleArrowUp,
      handleArrowDown,
      handleEnterDown,
      menuActiveIndex,
      t
    };
  }
});