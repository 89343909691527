import { useAppStore } from '@/stores/app';
import { useI18n } from 'vue-i18n';
import { computed, defineComponent, toRefs } from 'vue';
import { Article } from '@/components/ArticleCard';
export default defineComponent({
  name: 'ObFeatureList',
  components: {
    Article
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const appStore = useAppStore();
    const featurePosts = toRefs(props).data;
    const {
      t
    } = useI18n();
    return {
      gradientBackground: computed(() => {
        return {
          background: appStore.themeConfig.theme.header_gradient_css
        };
      }),
      gradientText: computed(() => appStore.themeConfig.theme.background_gradient_style),
      featurePosts,
      t
    };
  }
});