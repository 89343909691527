import { computed, defineComponent } from 'vue';
import { Dropdown, DropdownMenu, DropdownItem } from '@/components/Dropdown';
import { useAppStore } from '@/stores/app';
import ThemeToggle from '@/components/ToggleSwitch/ThemeToggle.vue';
import SearchModal from '@/components/SearchModal.vue';
import { useSearchStore } from '@/stores/search';
export default defineComponent({
  name: 'Controls',
  components: {
    Dropdown,
    DropdownMenu,
    DropdownItem,
    ThemeToggle,
    SearchModal
  },
  setup() {
    const appStore = useAppStore();
    const searchStore = useSearchStore();
    const handleClick = name => {
      appStore.changeLocale(name);
    };
    const handleOpenModal = status => {
      searchStore.setOpenModal(status);
    };
    return {
      handleOpenModal,
      handleClick,
      enableMultiLanguage: computed(() => appStore.themeConfig.site.multi_language)
    };
  }
});