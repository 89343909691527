import { computed, defineComponent, onMounted, ref } from 'vue';
import { SubTitle } from '@/components/Title';
import { useCategoryStore } from '@/stores/category';
import { useAppStore } from '@/stores/app';
export default defineComponent({
  name: 'ObArticleBox',
  components: {
    SubTitle
  },
  setup() {
    const appStore = useAppStore();
    const categoryStore = useCategoryStore();
    const loading = ref(true);
    const fetchData = async () => {
      await categoryStore.fetchCategories();
      loading.value = false;
    };
    onMounted(fetchData);
    return {
      loading,
      categories: computed(() => categoryStore.categories),
      gradientBackground: computed(() => {
        return {
          background: appStore.themeConfig.theme.header_gradient_css
        };
      })
    };
  }
});