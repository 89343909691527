import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5c1890fa"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "header-container"
};
const _hoisted_2 = {
  class: "site-header"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Logo = _resolveComponent("Logo");
  const _component_Navigation = _resolveComponent("Navigation");
  const _component_Controls = _resolveComponent("Controls");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("header", _hoisted_2, [_createVNode(_component_Logo), _createVNode(_component_Navigation), _createVNode(_component_Controls)])]);
}