import { computed, defineComponent } from 'vue';
import { useAppStore } from '@/stores/app';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'ObFooter',
  setup() {
    const appStore = useAppStore();
    const {
      t
    } = useI18n();
    return {
      avatarClass: computed(() => {
        return {
          'footer-avatar': true,
          [appStore.themeConfig.theme.profile_shape]: true
        };
      }),
      gradientText: computed(() => appStore.themeConfig.theme.background_gradient_style),
      gradientBackground: computed(() => {
        return {
          background: appStore.themeConfig.theme.header_gradient_css
        };
      }),
      currentYear: computed(() => new Date().getUTCFullYear()),
      themeConfig: computed(() => appStore.themeConfig),
      t
    };
  }
});