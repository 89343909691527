import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
  name: 'Example',
  setup() {
    const router = useRouter();
    const commentOffset = ref(0);
    const commentEl = ref();
    const backToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };
    const goBack = () => {
      router.back();
    };
    const jumpToComments = () => {
      commentEl.value = document.getElementById('comments');
      if (commentEl.value) {
        // 120 is the height of the header element
        commentOffset.value = commentEl.value && commentEl.value instanceof HTMLElement ? commentEl.value.offsetTop + 120 - 30 : 0;
      }
      window.scrollTo({
        top: commentOffset.value,
        behavior: 'smooth'
      });
    };
    return {
      goBack,
      backToTop,
      jumpToComments
    };
  }
});