import { useAppStore } from '@/stores/app';
import { computed, defineComponent, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'ObSubTitle',
  props: {
    title: {
      type: String,
      default: '',
      requried: true
    },
    side: {
      type: String,
      default: 'left'
    },
    icon: String
  },
  setup(props) {
    const appStore = useAppStore();
    const {
      t
    } = useI18n();
    const titleStr = toRefs(props).title;
    const side = toRefs(props).side;
    return {
      gradientBackground: computed(() => {
        return {
          background: appStore.themeConfig.theme.header_gradient_css
        };
      }),
      titleClass: computed(() => {
        return {
          'w-full': true,
          block: true,
          'text-right': side.value === 'right' ? true : false
        };
      }),
      lineClass: computed(() => {
        return {
          absolute: true,
          'bottom-0': true,
          'h-1': true,
          'w-14': true,
          'rounded-full': true,
          'right-0': side.value === 'right' ? true : false
        };
      }),
      titleStr,
      t
    };
  }
});