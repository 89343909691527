import { openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, renderList as _renderList, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "flex flex-col justify-center items-center"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "text-center pt-4 text-4xl font-semibold text-ob-bright"
};
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = {
  key: 3,
  class: "pt-6 px-10 w-full text-sm text-center flex flex-col gap-2"
};
const _hoisted_6 = {
  class: "grid grid-cols-3 pt-4 w-full px-2 text-lg"
};
const _hoisted_7 = {
  class: "col-span-1 text-center"
};
const _hoisted_8 = {
  class: "text-ob-bright"
};
const _hoisted_9 = {
  class: "text-base text-ob-dim"
};
const _hoisted_10 = {
  class: "col-span-1 text-center"
};
const _hoisted_11 = {
  class: "text-ob-bright"
};
const _hoisted_12 = {
  class: "text-base text-ob-dim"
};
const _hoisted_13 = {
  class: "col-span-1 text-center"
};
const _hoisted_14 = {
  class: "text-ob-bright"
};
const _hoisted_15 = {
  class: "text-base text-ob-dim"
};
const _hoisted_16 = {
  class: "flex flex-col justify-center items-center mt-8 w-full list-none text-ob-bright"
};
const _hoisted_17 = ["onClick"];
const _hoisted_18 = {
  key: 0,
  class: "relative z-50"
};
const _hoisted_19 = {
  key: 1,
  class: "relative z-50"
};
const _hoisted_20 = {
  key: 2,
  class: "relative z-50"
};
const _hoisted_21 = {
  key: 0,
  class: "relative z-50"
};
const _hoisted_22 = {
  key: 1,
  class: "relative z-50"
};
const _hoisted_23 = {
  key: 2,
  class: "relative z-50"
};
const _hoisted_24 = {
  key: 0,
  class: "relative z-50"
};
const _hoisted_25 = {
  key: 1,
  class: "relative z-50"
};
const _hoisted_26 = {
  key: 2,
  class: "relative z-50"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ob_skeleton = _resolveComponent("ob-skeleton");
  const _component_Social = _resolveComponent("Social");
  const _component_DropdownItem = _resolveComponent("DropdownItem");
  const _component_DropdownMenu = _resolveComponent("DropdownMenu");
  const _component_Dropdown = _resolveComponent("Dropdown");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_ctx.authorData.avatar !== '' ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    class: "diamond-avatar h-28 w-28 shadow-xl m-0",
    src: _ctx.authorData.avatar || _ctx.authorData.logo,
    alt: "avatar"
  }, null, 8 /* PROPS */, _hoisted_2)) : (_openBlock(), _createBlock(_component_ob_skeleton, {
    key: 1,
    width: "7rem",
    height: "7rem",
    circle: ""
  })), _createElementVNode("h2", _hoisted_3, [_ctx.authorData.name ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createTextVNode(_toDisplayString(_ctx.authorData.name), 1 /* TEXT */)], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createBlock(_component_ob_skeleton, {
    key: 1,
    height: "2.25rem",
    width: "7rem"
  }))]), _createElementVNode("span", {
    class: "h-1 w-14 rounded-full mt-2",
    style: _normalizeStyle(_ctx.gradientBackground)
  }, null, 4 /* STYLE */), _ctx.authorData.description ? (_openBlock(), _createElementBlock("p", {
    key: 2,
    class: "pt-6 px-2 w-full text-sm text-center text-ob-dim",
    innerHTML: _ctx.authorData.description
  }, null, 8 /* PROPS */, _hoisted_4)) : (_openBlock(), _createElementBlock("p", _hoisted_5, [_createVNode(_component_ob_skeleton, {
    count: 2,
    height: "20px",
    width: "10rem"
  })])), _createVNode(_component_Social, {
    socials: _ctx.authorData.socials
  }, null, 8 /* PROPS */, ["socials"]), _createElementVNode("ul", _hoisted_6, [_createElementVNode("li", _hoisted_7, [_createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.authorData.post_list.length), 1 /* TEXT */), _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.t('settings.articles')), 1 /* TEXT */)]), _createElementVNode("li", _hoisted_10, [_createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.authorData.categories), 1 /* TEXT */), _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.t('settings.categories')), 1 /* TEXT */)]), _createElementVNode("li", _hoisted_13, [_createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.authorData.tags), 1 /* TEXT */), _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.t('settings.tags')), 1 /* TEXT */)])])]), _createElementVNode("ul", _hoisted_16, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, route => {
    return _openBlock(), _createElementBlock("li", {
      class: "pb-2 cursor-pointer",
      key: route.path
    }, [route.children && route.children.length === 0 ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: "text-sm block px-1.5 py-0.5 rounded-md relative uppercase",
      onClick: $event => _ctx.pushPage(route.path)
    }, [_ctx.$i18n.locale === 'cn' && route.i18n.cn ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(route.i18n.cn), 1 /* TEXT */)) : _ctx.$i18n.locale === 'en' && route.i18n.en ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(route.i18n.en), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(route.name), 1 /* TEXT */))], 8 /* PROPS */, _hoisted_17)) : (_openBlock(), _createBlock(_component_Dropdown, {
      key: 1,
      onCommand: _ctx.pushPage,
      class: "flex flex-col justify-center items-center nav-link text-sm block px-1.5 py-0.5 rounded-md relative uppercase"
    }, {
      default: _withCtx(() => [_ctx.$i18n.locale === 'cn' && route.i18n.cn ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(route.i18n.cn), 1 /* TEXT */)) : _ctx.$i18n.locale === 'en' && route.i18n.en ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(route.i18n.en), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(route.name), 1 /* TEXT */)), _createVNode(_component_DropdownMenu, {
        expand: ""
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(route.children, sub => {
          return _openBlock(), _createBlock(_component_DropdownItem, {
            key: sub.path,
            name: sub.path
          }, {
            default: _withCtx(() => [_ctx.$i18n.locale === 'cn' && sub.i18n.cn ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(sub.i18n.cn), 1 /* TEXT */)) : _ctx.$i18n.locale === 'en' && sub.i18n.en ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(sub.i18n.en), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(sub.name), 1 /* TEXT */))]),

            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["name"]);
        }), 128 /* KEYED_FRAGMENT */))]),

        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */)]),

      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onCommand"]))]);
  }), 128 /* KEYED_FRAGMENT */))])], 64 /* STABLE_FRAGMENT */);
}