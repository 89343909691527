import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-fce77350"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "flex items-start self-stretch relative"
};
const _hoisted_2 = {
  key: 0,
  class: "flex text-4xl"
};
const _hoisted_3 = {
  key: 1,
  class: "flex text-4xl animation-text"
};
const _hoisted_4 = {
  class: "font-extrabold text-xs uppercase"
};
const _hoisted_5 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "flex flex-col relative py-4 z-10 text-white font-medium ob-drop-shadow cursor-pointer",
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.handleLogoClick && _ctx.handleLogoClick(...args))
  }, [_ctx.themeConfig.site.author ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.themeConfig.site.author), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", _hoisted_3, "LOADING")), _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.themeConfig.site.nick || 'BLOG'), 1 /* TEXT */)]), _createElementVNode("img", {
    class: "logo-image",
    src: _ctx.themeConfig.site.logo || _ctx.themeConfig.site.avatar,
    alt: "site-logo"
  }, null, 8 /* PROPS */, _hoisted_5)]);
}