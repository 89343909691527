import "core-js/modules/es.array.push.js";
import { useAppStore } from '@/stores/app';
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
  name: 'Logo',
  setup() {
    const appStore = useAppStore();
    const router = useRouter();
    const handleLogoClick = () => {
      router.push('/');
    };
    return {
      handleLogoClick,
      themeConfig: computed(() => appStore.themeConfig)
    };
  }
});