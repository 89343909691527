import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue";
import _imports_0 from '@/assets/default-cover.jpg';
const _hoisted_1 = {
  class: "article-container"
};
const _hoisted_2 = {
  key: 0,
  class: "article-tag"
};
const _hoisted_3 = {
  key: 1,
  class: "article-tag"
};
const _hoisted_4 = {
  class: "feature-article"
};
const _hoisted_5 = {
  class: "feature-thumbnail"
};
const _hoisted_6 = {
  key: 0,
  class: "ob-hz-thumbnail"
};
const _hoisted_7 = {
  key: 1,
  class: "ob-hz-thumbnail",
  src: _imports_0
};
const _hoisted_8 = {
  class: "feature-content"
};
const _hoisted_9 = {
  key: 0
};
const _hoisted_10 = {
  key: 1
};
const _hoisted_11 = {
  key: 1
};
const _hoisted_12 = {
  "data-dia": "article-link"
};
const _hoisted_13 = {
  key: 2
};
const _hoisted_14 = {
  key: 4,
  class: "article-footer"
};
const _hoisted_15 = {
  class: "flex flex-row items-center"
};
const _hoisted_16 = {
  class: "text-ob-dim"
};
const _hoisted_17 = {
  key: 5,
  class: "article-footer"
};
const _hoisted_18 = {
  class: "flex flex-row items-center mt-6"
};
const _hoisted_19 = {
  class: "text-ob-dim mt-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_svg_icon = _resolveComponent("svg-icon");
  const _component_ob_skeleton = _resolveComponent("ob-skeleton");
  const _component_router_link = _resolveComponent("router-link");
  const _directive_lazy = _resolveDirective("lazy");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.post.pinned ? (_openBlock(), _createElementBlock("span", _hoisted_2, [_createElementVNode("b", null, [_createVNode(_component_svg_icon, {
    "icon-class": "pin"
  }), _createTextVNode(" " + _toDisplayString(_ctx.t('settings.pinned')), 1 /* TEXT */)])])) : _ctx.post.feature ? (_openBlock(), _createElementBlock("span", _hoisted_3, [_createElementVNode("b", null, [_createVNode(_component_svg_icon, {
    "icon-class": "hot"
  }), _createTextVNode(" " + _toDisplayString(_ctx.t('settings.featured')), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_ctx.post.cover ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_6, null, 512 /* NEED_PATCH */)), [[_directive_lazy, _ctx.post.cover]]) : (_openBlock(), _createElementBlock("img", _hoisted_7)), _createElementVNode("span", {
    class: "thumbnail-screen",
    style: _normalizeStyle(_ctx.bannerHoverGradient)
  }, null, 4 /* STYLE */)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("span", null, [_ctx.post.categories && _ctx.post.categories.length > 0 ? (_openBlock(), _createElementBlock("b", _hoisted_9, _toDisplayString(_ctx.post.categories[0].name), 1 /* TEXT */)) : _ctx.post.categories && _ctx.post.categories.length <= 0 ? (_openBlock(), _createElementBlock("b", _hoisted_10, _toDisplayString(_ctx.t('settings.default-category')), 1 /* TEXT */)) : (_openBlock(), _createBlock(_component_ob_skeleton, {
    key: 2,
    tag: "b",
    height: "20px",
    width: "35px"
  })), _createElementVNode("ul", null, [_ctx.post.tags && _ctx.post.tags.length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList(_ctx.post.tags, tag => {
    return _openBlock(), _createElementBlock("li", {
      key: tag.slug
    }, [_createElementVNode("em", null, "# " + _toDisplayString(tag.name), 1 /* TEXT */)]);
  }), 128 /* KEYED_FRAGMENT */)) : _ctx.post.tags && _ctx.post.tags.length <= 0 ? (_openBlock(), _createElementBlock("li", _hoisted_11, [_createElementVNode("em", null, "# " + _toDisplayString(_ctx.t('settings.default-tag')), 1 /* TEXT */)])) : (_openBlock(), _createBlock(_component_ob_skeleton, {
    key: 2,
    count: 2,
    tag: "li",
    height: "16px",
    width: "35px"
  }))])]), _ctx.post.title ? (_openBlock(), _createBlock(_component_router_link, {
    key: 0,
    to: {
      name: 'post',
      params: {
        slug: _ctx.post.slug
      }
    }
  }, {
    default: _withCtx(() => [_createElementVNode("h1", _hoisted_12, _toDisplayString(_ctx.post.title), 1 /* TEXT */)]),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["to"])) : (_openBlock(), _createBlock(_component_ob_skeleton, {
    key: 1,
    tag: "h1",
    height: "3rem"
  })), _ctx.post.text ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.post.text), 1 /* TEXT */)) : (_openBlock(), _createBlock(_component_ob_skeleton, {
    key: 3,
    tag: "p",
    count: 3,
    height: "20px"
  })), _ctx.post.count_time ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_withDirectives(_createElementVNode("img", {
    class: "hover:opacity-50 cursor-pointer",
    alt: "",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.handleAuthorClick(_ctx.post.author.link))
  }, null, 512 /* NEED_PATCH */), [[_directive_lazy, _ctx.post.author.avatar]]), _createElementVNode("span", _hoisted_16, [_createElementVNode("strong", {
    class: "text-ob-normal pr-1.5 hover:text-ob hover:opacity-50 cursor-pointer",
    onClick: _cache[1] || (_cache[1] = $event => _ctx.handleAuthorClick(_ctx.post.author.link))
  }, _toDisplayString(_ctx.post.author.name), 1 /* TEXT */), _createTextVNode(" " + _toDisplayString(_ctx.t('settings.shared-on')) + " " + _toDisplayString(_ctx.t(_ctx.post.date.month)) + " " + _toDisplayString(_ctx.post.date.day) + ", " + _toDisplayString(_ctx.post.date.year), 1 /* TEXT */)])])])) : (_openBlock(), _createElementBlock("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, [_createVNode(_component_ob_skeleton, {
    class: "mr-2",
    height: "28px",
    width: "28px",
    circle: true
  }), _createElementVNode("span", _hoisted_19, [_createVNode(_component_ob_skeleton, {
    height: "20px",
    width: "150px"
  })])])]))])])]);
}