import { renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_click_away = _resolveDirective("click-away");
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "ob-dropdown relative z-50",
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.toggle && _ctx.toggle(...args)),
    onMouseover: _cache[1] || (_cache[1] =
    //@ts-ignore
    (...args) => _ctx.hoverHandler && _ctx.hoverHandler(...args)),
    onMouseleave: _cache[2] || (_cache[2] =
    //@ts-ignore
    (...args) => _ctx.leaveHandler && _ctx.leaveHandler(...args))
  }, [_renderSlot(_ctx.$slots, "default")], 32 /* HYDRATE_EVENTS */)), [[_directive_click_away, _ctx.onClickAway]]);
}