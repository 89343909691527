import { computed, defineComponent, onMounted, ref } from 'vue';
import { Feature, FeatureList } from '@/components/Feature';
import { Article, HorizontalArticle } from '@/components/ArticleCard';
import { Title } from '@/components/Title';
import { Sidebar, TagBox, RecentComment, Profile } from '@/components/Sidebar';
import { usePostStore } from '@/stores/post';
import { FeaturePosts, PostList } from '@/models/Post.class';
import { useAppStore } from '@/stores/app';
import { useI18n } from 'vue-i18n';
import { useCategoryStore } from '@/stores/category';
import Paginator from '@/components/Paginator.vue';
import { useMetaStore } from '@/stores/meta';
export default defineComponent({
  name: 'Home',
  components: {
    Feature,
    FeatureList,
    Article,
    HorizontalArticle,
    Title,
    Sidebar,
    TagBox,
    Paginator,
    RecentComment,
    Profile
  },
  setup() {
    useMetaStore().setTitle('home');
    const postStore = usePostStore();
    const appStore = useAppStore();
    const categoryStore = useCategoryStore();
    const {
      t
    } = useI18n();
    /** Variables Section */
    const topFeature = ref(new FeaturePosts().top_feature);
    const featurePosts = ref(new FeaturePosts().features);
    const posts = ref(new PostList());
    const expanderClass = ref({
      'tab-expander': true,
      expanded: false
    });
    const tabClass = ref({
      tab: true,
      'expanded-tab': false
    });
    const activeTab = ref('');
    const articleOffset = ref(0);
    const pagination = ref({
      pageSize: 12,
      pageTotal: 0,
      page: 1
    });
    /** Function section */
    const fetchData = async () => {
      await postStore.fetchFeaturePosts().then(() => {
        topFeature.value = postStore.featurePosts.top_feature;
        featurePosts.value = postStore.featurePosts.features;
      });
      await fetchPostData();
      await categoryStore.fetchCategories();
      const articleListEl = document.getElementById('article-list');
      // 120 is the height of the header element
      articleOffset.value = articleListEl && articleListEl instanceof HTMLElement ? articleListEl.offsetTop + 120 : 0;
    };
    onMounted(fetchData);
    const expandHandler = () => {
      expanderClass.value.expanded = !expanderClass.value.expanded;
      tabClass.value['expanded-tab'] = !tabClass.value['expanded-tab'];
    };
    const handleTabChange = slug => {
      activeTab.value = slug;
      backToArticleTop();
      if (slug !== '') {
        posts.value = new PostList();
        postStore.fetchPostsByCategory(slug).then(postList => {
          posts.value = postList;
          pagination.value.pageTotal = postList.total;
        });
      } else {
        fetchPostData();
      }
    };
    const backToArticleTop = () => {
      window.scrollTo({
        top: articleOffset.value
      });
    };
    const activeTabStyle = slug => {
      if (slug === activeTab.value) return {
        background: appStore.themeConfig.theme.header_gradient_css
      };
      return {};
    };
    const fetchPostData = async () => {
      posts.value = new PostList();
      await postStore.fetchPostsList(pagination.value.page).then(() => {
        posts.value = postStore.posts;
        pagination.value.pageTotal = postStore.posts.total;
        pagination.value.pageSize = postStore.posts.pageSize;
      });
    };
    const pageChangeHanlder = async page => {
      pagination.value.page = page;
      backToArticleTop();
      await fetchPostData();
    };
    return {
      gradientText: computed(() => appStore.themeConfig.theme.background_gradient_style),
      gradientBackground: computed(() => {
        return {
          background: appStore.themeConfig.theme.header_gradient_css
        };
      }),
      themeConfig: computed(() => appStore.themeConfig),
      categories: computed(() => {
        if (categoryStore.isLoaded && categoryStore.categories.length === 0) {
          return null;
        }
        return categoryStore.categories;
      }),
      mainAuthor: computed(() => {
        const author = appStore.themeConfig.site.author.toLocaleLowerCase();
        return author.replace(/[\s]+/g, '-');
      }),
      recentCommentEnable: computed(() => {
        return false;
        // return (
        //   (appStore.themeConfig.plugins.gitalk.enable &&
        //     appStore.themeConfig.plugins.gitalk.recentComment) ||
        //   (!appStore.themeConfig.plugins.gitalk.enable &&
        //     appStore.themeConfig.plugins.valine.enable &&
        //     appStore.themeConfig.plugins.valine.recentComment)
        // )
      }),

      expanderClass,
      tabClass,
      expandHandler,
      handleTabChange,
      topFeature,
      featurePosts,
      posts,
      activeTabStyle,
      activeTab,
      pagination,
      pageChangeHanlder,
      t
    };
  }
});