import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "block"
};
const _hoisted_2 = {
  key: 2
};
const _hoisted_3 = {
  class: "main-grid"
};
const _hoisted_4 = {
  class: "flex flex-col relative"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  class: "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8"
};
const _hoisted_7 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FeatureList = _resolveComponent("FeatureList");
  const _component_Feature = _resolveComponent("Feature");
  const _component_horizontal_article = _resolveComponent("horizontal-article");
  const _component_Title = _resolveComponent("Title");
  const _component_ob_skeleton = _resolveComponent("ob-skeleton");
  const _component_svg_icon = _resolveComponent("svg-icon");
  const _component_Article = _resolveComponent("Article");
  const _component_Paginator = _resolveComponent("Paginator");
  const _component_Profile = _resolveComponent("Profile");
  const _component_RecentComment = _resolveComponent("RecentComment");
  const _component_TagBox = _resolveComponent("TagBox");
  const _component_Sidebar = _resolveComponent("Sidebar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.themeConfig.theme.feature ? (_openBlock(), _createBlock(_component_Feature, {
    key: 0,
    data: _ctx.topFeature
  }, {
    default: _withCtx(() => [_createVNode(_component_FeatureList, {
      data: _ctx.featurePosts
    }, null, 8 /* PROPS */, ["data"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["data"])) : (_openBlock(), _createBlock(_component_horizontal_article, {
    key: 1,
    class: "mb-8",
    data: _ctx.posts.data[0] || {}
  }, null, 8 /* PROPS */, ["data"])), _ctx.themeConfig.theme.feature ? (_openBlock(), _createElementBlock("span", _hoisted_2, [_createVNode(_component_Title, {
    id: "article-list",
    title: 'titles.articles',
    icon: "article"
  }, null, 8 /* PROPS */, ["title"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("ul", {
    class: _normalizeClass(_ctx.tabClass)
  }, [_createElementVNode("li", {
    class: _normalizeClass({
      active: _ctx.activeTab === ''
    }),
    onClick: _cache[0] || (_cache[0] = $event => _ctx.handleTabChange(''))
  }, [_createElementVNode("span", {
    class: "first-tab",
    style: _normalizeStyle(_ctx.activeTabStyle(''))
  }, _toDisplayString(_ctx.t('settings.button-all')), 5 /* TEXT, STYLE */)], 2 /* CLASS */), _ctx.categories && _ctx.categories.length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList(_ctx.categories, category => {
    return _openBlock(), _createElementBlock("li", {
      key: category.slug,
      class: _normalizeClass({
        active: _ctx.activeTab === category.slug
      }),
      onClick: $event => _ctx.handleTabChange(category.slug)
    }, [_createElementVNode("span", {
      style: _normalizeStyle(_ctx.activeTabStyle(category.slug))
    }, _toDisplayString(category.name), 5 /* TEXT, STYLE */), _createElementVNode("b", null, _toDisplayString(category.count), 1 /* TEXT */)], 10 /* CLASS, PROPS */, _hoisted_5);
  }), 128 /* KEYED_FRAGMENT */)) : _ctx.categories !== null ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, _renderList(6, i => {
    return _createElementVNode("li", {
      key: i,
      style: {
        "position": "relative",
        "top": "-4px"
      }
    }, [_createVNode(_component_ob_skeleton, {
      tag: "span",
      width: "60px",
      height: "33px"
    })]);
  }), 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)], 2 /* CLASS */), _createElementVNode("span", {
    class: _normalizeClass(_ctx.expanderClass),
    onClick: _cache[1] || (_cache[1] =
    //@ts-ignore
    (...args) => _ctx.expandHandler && _ctx.expandHandler(...args))
  }, [_createVNode(_component_svg_icon, {
    "icon-class": "chevron"
  })], 2 /* CLASS */), _createElementVNode("ul", _hoisted_6, [_ctx.posts.data.length === 0 ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList(6, n => {
    return _createElementVNode("li", {
      key: n
    }, [_createVNode(_component_Article, {
      data: {}
    })]);
  }), 64 /* STABLE_FRAGMENT */)) : !_ctx.themeConfig.theme.feature ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 1
  }, _renderList(_ctx.posts.data, (post, key) => {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: post.slug
    }, [key !== 0 ? (_openBlock(), _createElementBlock("li", _hoisted_7, [_createVNode(_component_Article, {
      data: post
    }, null, 8 /* PROPS */, ["data"])])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
  }), 128 /* KEYED_FRAGMENT */)) : (_openBlock(true), _createElementBlock(_Fragment, {
    key: 2
  }, _renderList(_ctx.posts.data, post => {
    return _openBlock(), _createElementBlock("li", {
      key: post.slug
    }, [_createVNode(_component_Article, {
      data: post
    }, null, 8 /* PROPS */, ["data"])]);
  }), 128 /* KEYED_FRAGMENT */))]), _createVNode(_component_Paginator, {
    pageSize: _ctx.pagination.pageSize,
    pageTotal: _ctx.pagination.pageTotal,
    page: _ctx.pagination.page,
    onPageChange: _ctx.pageChangeHanlder
  }, null, 8 /* PROPS */, ["pageSize", "pageTotal", "page", "onPageChange"])]), _createElementVNode("div", null, [_createVNode(_component_Sidebar, null, {
    default: _withCtx(() => [_createVNode(_component_Profile, {
      author: _ctx.mainAuthor
    }, null, 8 /* PROPS */, ["author"]), _ctx.recentCommentEnable ? (_openBlock(), _createBlock(_component_RecentComment, {
      key: 0
    })) : _createCommentVNode("v-if", true), _createVNode(_component_TagBox)]),
    _: 1 /* STABLE */
  })])])]);
}