import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "relative flex items-center pb-2 mb-4 text-xl text-ob-bright uppercase"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_svg_icon = _resolveComponent("svg-icon");
  return _openBlock(), _createElementBlock("p", _hoisted_1, [_ctx.icon && _ctx.side === 'left' ? (_openBlock(), _createBlock(_component_svg_icon, {
    key: 0,
    "icon-class": _ctx.icon,
    class: "inline-block mr-2"
  }, null, 8 /* PROPS */, ["icon-class"])) : _createCommentVNode("v-if", true), _createElementVNode("span", {
    class: _normalizeClass(_ctx.titleClass)
  }, _toDisplayString(_ctx.t(_ctx.titleStr)), 3 /* TEXT, CLASS */), _ctx.icon && _ctx.side === 'right' ? (_openBlock(), _createBlock(_component_svg_icon, {
    key: 1,
    "icon-class": _ctx.icon,
    class: "inline-block ml-2"
  }, null, 8 /* PROPS */, ["icon-class"])) : _createCommentVNode("v-if", true), _createElementVNode("span", {
    class: _normalizeClass(_ctx.lineClass),
    style: _normalizeStyle(_ctx.gradientBackground)
  }, null, 6 /* CLASS, STYLE */)]);
}