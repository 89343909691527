import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-37d27d39"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "origin-top-right absolute right-0 mt-2 w-48 bg-ob-deep-900 rounded-lg py-2 shadow-md"
};
const _hoisted_2 = {
  key: 1,
  class: "flex flex-col justify-center items-center mt-2 w-48 bg-ob-deep-900 rounded-lg py-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock(_Transition, {
    name: "dropdown-content"
  }, {
    default: _withCtx(() => [!_ctx.expand && _ctx.active ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])) : _ctx.expand && _ctx.active ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])) : _createCommentVNode("v-if", true)]),
    _: 3 /* FORWARDED */
  });
}