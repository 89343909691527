import { defineComponent, toRefs } from 'vue';
import HorizontalArticle from '@/components/ArticleCard/src/HorizontalArticle.vue';
export default defineComponent({
  name: 'Feature',
  props: {
    data: Object
  },
  components: {
    HorizontalArticle
  },
  setup(props) {
    const featurePost = toRefs(props).data;
    return {
      featurePost
    };
  }
});