import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6331c252"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "Ob-Navigator-tips"
};
const _hoisted_2 = {
  key: 2,
  class: "text-sm"
};
const _hoisted_3 = {
  class: "Ob-Navigator-submenu"
};
const _hoisted_4 = {
  class: "Ob-Navigator-tips"
};
const _hoisted_5 = {
  class: "Ob-Navigator-tips"
};
const _hoisted_6 = {
  class: "Ob-Navigator-tips"
};
const _hoisted_7 = {
  class: "Ob-Navigator-tips"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_svg_icon = _resolveComponent("svg-icon");
  return _openBlock(), _createElementBlock("div", {
    id: "Ob-Navigator",
    class: _normalizeClass({
      'Ob-Navigator--open': _ctx.openNavigator,
      'Ob-Navigator--scrolling': _ctx.scrolling
    })
  }, [_createVNode(_Transition, {
    name: "fade-bounce-y",
    mode: "out-in"
  }, {
    default: _withCtx(() => [!_ctx.openNavigator && _ctx.showProgress ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      onClick: _cache[0] || (_cache[0] = _withModifiers(
      //@ts-ignore
      (...args) => _ctx.handleBackToTop && _ctx.handleBackToTop(...args), ["stop", "prevent"])),
      class: "Ob-Navigator-btt"
    }, [_createElementVNode("div", null, [_createVNode(_component_svg_icon, {
      class: "text-ob-bright stroke-current",
      "icon-class": "nav-top"
    })]), _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.t('settings.tips-back-to-top')), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]),
    _: 1 /* STABLE */
  }), _createElementVNode("div", {
    class: "Ob-Navigator-ball",
    onClick: _cache[1] || (_cache[1] = _withModifiers(
    //@ts-ignore
    (...args) => _ctx.handleNavigatorToggle && _ctx.handleNavigatorToggle(...args), ["stop", "prevent"]))
  }, [_createElementVNode("div", {
    style: _normalizeStyle(_ctx.gradient)
  }, [_createVNode(_Transition, {
    name: "fade-bounce-y",
    mode: "out-in"
  }, {
    default: _withCtx(() => [_ctx.openNavigator ? (_openBlock(), _createBlock(_component_svg_icon, {
      key: 0,
      class: "text-base stroke-2",
      "icon-class": "close"
    })) : !_ctx.showProgress ? (_openBlock(), _createBlock(_component_svg_icon, {
      key: 1,
      "icon-class": "dots"
    })) : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.progress) + "%", 1 /* TEXT */))]),

    _: 1 /* STABLE */
  })], 4 /* STYLE */)]), _createElementVNode("ul", _hoisted_3, [_createElementVNode("li", {
    id: "Ob-Navigator-top",
    style: _normalizeStyle(_ctx.gradient),
    onClick: _cache[2] || (_cache[2] = _withModifiers(
    //@ts-ignore
    (...args) => _ctx.handleBackToTop && _ctx.handleBackToTop(...args), ["stop", "prevent"]))
  }, [_createElementVNode("div", null, [_createVNode(_component_svg_icon, {
    class: "text-ob-bright stroke-current",
    "icon-class": "nav-top"
  })]), _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t('settings.tips-back-to-top')), 1 /* TEXT */)], 4 /* STYLE */), _ctx.isMobile ? (_openBlock(), _createElementBlock("li", {
    key: 0,
    id: "Ob-Navigator-menu",
    style: _normalizeStyle(_ctx.gradient),
    onClick: _cache[3] || (_cache[3] = _withModifiers(
    //@ts-ignore
    (...args) => _ctx.handleOpenMenu && _ctx.handleOpenMenu(...args), ["stop", "prevent"]))
  }, [_createElementVNode("div", null, [_createVNode(_component_svg_icon, {
    class: "text-ob-bright stroke-current",
    "icon-class": "nav-menu"
  })]), _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t('settings.tips-open-menu')), 1 /* TEXT */)], 4 /* STYLE */)) : _createCommentVNode("v-if", true), _createElementVNode("li", {
    id: "Ob-Navigator-home",
    style: _normalizeStyle(_ctx.gradient),
    onClick: _cache[4] || (_cache[4] = _withModifiers(
    //@ts-ignore
    (...args) => _ctx.handleGoHome && _ctx.handleGoHome(...args), ["stop", "prevent"]))
  }, [_createElementVNode("div", null, [_createVNode(_component_svg_icon, {
    class: "text-ob-bright stroke-current",
    "icon-class": "nav-home"
  })]), _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('settings.tips-back-to-home')), 1 /* TEXT */)], 4 /* STYLE */), _createElementVNode("li", {
    id: "Ob-Navigator-search",
    style: _normalizeStyle(_ctx.gradient),
    onClick: _cache[5] || (_cache[5] = _withModifiers(
    //@ts-ignore
    (...args) => _ctx.handleSearch && _ctx.handleSearch(...args), ["stop", "prevent"]))
  }, [_createElementVNode("div", null, [_createVNode(_component_svg_icon, {
    class: "text-ob-bright stroke-current",
    "icon-class": "search"
  })]), _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('settings.tips-open-search')), 1 /* TEXT */)], 4 /* STYLE */)])], 2 /* CLASS */);
}