import { computed, defineComponent, toRefs } from 'vue';
export default defineComponent({
  name: 'AuSocial',
  props: {
    socials: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  setup(props) {
    const socials = toRefs(props).socials;
    return {
      customSocials: computed(() => socials.value.customs.socials)
    };
  }
});