import _defineProperty from "/home/runner/work/adminy.github.io/adminy.github.io/themes/aurora/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { Social } from './ThemeConfig.class';
export class NavPost {
  constructor(raw) {
    _defineProperty(this, "title", '');
    _defineProperty(this, "uid", '');
    _defineProperty(this, "slug", '');
    _defineProperty(this, "date", '');
    _defineProperty(this, "updated", '');
    _defineProperty(this, "comments", '');
    _defineProperty(this, "path", '');
    _defineProperty(this, "keywords", '');
    _defineProperty(this, "cover", '');
    _defineProperty(this, "text", '');
    _defineProperty(this, "link", '');
    _defineProperty(this, "photos", '');
    _defineProperty(this, "count_time", {});
    _defineProperty(this, "categories", {});
    _defineProperty(this, "tags", {});
    _defineProperty(this, "author", {});
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          if (key === 'date') {
            const m = new Date(raw[key]);
            const translateMonth = `settings.months[${m.getMonth()}]`;
            raw[key] = Object.create({
              month: translateMonth,
              day: m.getUTCDate(),
              year: m.getUTCFullYear()
            });
          }
          Object.assign(this, {
            [key]: raw[key]
          });
        }
      }
    }
  }
}
export class Post {
  constructor(raw) {
    _defineProperty(this, "title", '');
    _defineProperty(this, "uid", '');
    _defineProperty(this, "slug", '');
    _defineProperty(this, "date", {
      month: '',
      day: 0,
      year: 0
    });
    _defineProperty(this, "updated", '');
    _defineProperty(this, "comments", false);
    _defineProperty(this, "path", '');
    _defineProperty(this, "excerpt", null);
    _defineProperty(this, "keywords", null);
    _defineProperty(this, "cover", '');
    _defineProperty(this, "content", null);
    _defineProperty(this, "text", '');
    _defineProperty(this, "link", '');
    _defineProperty(this, "raw", null);
    _defineProperty(this, "photos", []);
    _defineProperty(this, "categories", []);
    _defineProperty(this, "tags", []);
    _defineProperty(this, "min_tags", []);
    _defineProperty(this, "count_time", {});
    _defineProperty(this, "toc", '');
    _defineProperty(this, "next_post", {});
    _defineProperty(this, "prev_post", {});
    _defineProperty(this, "author", {
      name: '',
      avatar: '',
      link: ''
    });
    _defineProperty(this, "feature", false);
    _defineProperty(this, "pinned", false);
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          if (key === 'categories') {
            Object.assign(this, {
              [key]: raw[key].map(one => new Category(one))
            });
          } else if (key === 'tags') {
            Object.assign(this, {
              [key]: raw[key].map(one => new Tag(one))
            });
            this.min_tags = this.tags.filter((value, index) => {
              if (index < 2) return true;
            });
          } else if (key === 'prev_post' || key === 'next_post') {
            Object.assign(this, {
              [key]: new NavPost(raw[key])
            });
          } else {
            if (key === 'date') {
              const m = new Date(raw[key]);
              const translateMonth = `settings.months[${m.getMonth()}]`;
              raw[key] = Object.create({
                month: translateMonth,
                day: m.getUTCDate(),
                year: m.getUTCFullYear()
              });
            }
            Object.assign(this, {
              [key]: raw[key]
            });
          }
        }
      }
    }
  }
}
export class PostList {
  constructor(raw) {
    _defineProperty(this, "data", []);
    _defineProperty(this, "pageCount", 0);
    _defineProperty(this, "pageSize", 0);
    _defineProperty(this, "total", 0);
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          if (key === 'data') {
            Object.assign(this, {
              [key]: raw[key].map(one => new Post(one))
            });
          } else {
            Object.assign(this, {
              [key]: raw[key]
            });
          }
        }
      }
    }
  }
}
export class SpecificPostsList {
  constructor(raw) {
    _defineProperty(this, "data", []);
    _defineProperty(this, "pageCount", 0);
    _defineProperty(this, "pageSize", 0);
    _defineProperty(this, "total", 0);
    if (raw && raw.postlist) {
      Object.assign(this, {
        data: raw.postlist.map(one => new Post(one)),
        pageCount: raw.postlist.length,
        pageSize: raw.postlist.length,
        total: raw.postlist.length
      });
    }
  }
}
export class FeaturePosts {
  constructor(raw) {
    _defineProperty(this, "top_feature", {});
    _defineProperty(this, "features", []);
    if (raw) {
      Object.assign(this, {
        top_feature: new Post(raw.shift())
      });
      Object.assign(this, {
        features: raw.map(one => new Post(one))
      });
    }
  }
}
export class AuthorPosts {
  constructor(raw) {
    _defineProperty(this, "name", '');
    _defineProperty(this, "slug", '');
    _defineProperty(this, "avatar", '');
    _defineProperty(this, "link", '');
    _defineProperty(this, "description", '');
    _defineProperty(this, "socials", new Social());
    _defineProperty(this, "categories", 0);
    _defineProperty(this, "tags", 0);
    _defineProperty(this, "word_count", '0');
    _defineProperty(this, "post_list", []);
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          if (key === 'socials') {
            Object.assign(this, {
              [key]: new Social(raw[key])
            });
          } else if (key === 'post_list') {
            Object.assign(this, {
              post_list: raw[key].map(one => new Post(one))
            });
          } else {
            Object.assign(this, {
              [key]: raw[key]
            });
          }
        }
      }
    }
  }
}
export class Categories {
  constructor(raw) {
    _defineProperty(this, "data", []);
    if (raw) {
      Object.assign(this, {
        data: raw.map(one => new Category(one))
      });
    }
  }
}
export class Category {
  constructor(raw) {
    _defineProperty(this, "name", '');
    _defineProperty(this, "slug", '');
    _defineProperty(this, "path", '');
    _defineProperty(this, "count", 0);
    _defineProperty(this, "parent", '');
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          Object.assign(this, {
            [key]: raw[key]
          });
        }
      }
      if (!(raw instanceof Category)) {
        this.parent = this.slug.split('/').filter((v, i, a) => i !== a.length - 1).join('/');
      }
    }
  }
}
export class Tags {
  constructor(raw) {
    _defineProperty(this, "data", []);
    if (raw) {
      Object.assign(this, {
        data: raw.map(one => new Tag(one))
      });
    }
  }
}
export class Tag {
  constructor(raw) {
    _defineProperty(this, "name", '');
    _defineProperty(this, "slug", '');
    _defineProperty(this, "path", '');
    _defineProperty(this, "count", 0);
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          Object.assign(this, {
            [key]: raw[key]
          });
        }
      }
    }
  }
}
export class Archives {
  constructor(raw) {
    _defineProperty(this, "data", []);
    _defineProperty(this, "pageCount", 0);
    _defineProperty(this, "pageSize", 0);
    _defineProperty(this, "total", 0);
    const postData = new Map();
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          if (key === 'data') {
            // Use the natural of hashmap keys to
            // group posts with month and year
            raw[key].forEach(one => {
              const post = new Post(one);
              const groupKey = `${post.date.month}-${post.date.year}`;
              if (postData.has(groupKey)) {
                const groupedPost = postData.get(groupKey);
                groupedPost.posts.push(post);
              } else {
                postData.set(groupKey, {
                  month: post.date.month,
                  year: post.date.year,
                  posts: [post]
                });
              }
            });
            // Covert map back to object array
            const data = [];
            for (const item of postData.values()) {
              data.push(item);
            }
            // Assigning data
            Object.assign(this, {
              data: data
            });
          } else {
            Object.assign(this, {
              [key]: raw[key]
            });
          }
        }
      }
    }
  }
}