import { useCommonStore } from '@/stores/common';
import { computed, defineComponent } from 'vue';
export default defineComponent({
  name: 'ObSidebar',
  setup() {
    const commonStore = useCommonStore();
    return {
      isMobile: computed(() => commonStore.isMobile)
    };
  }
});