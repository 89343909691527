import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "sidebar-box"
};
const _hoisted_2 = {
  class: "flex justify-event flex-wrap gap-2 pt-2 cursor-pointer"
};
const _hoisted_3 = {
  class: "bg-ob-deep-900 text-center px-3 py-1 rounded-tl-md rounded-bl-md text-sm"
};
const _hoisted_4 = {
  class: "bg-ob-deep-900 text-ob text-center px-2 py-1 rounded-tr-md rounded-br-md text-sm opacity-70"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SubTitle = _resolveComponent("SubTitle");
  const _component_ob_skeleton = _resolveComponent("ob-skeleton");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_SubTitle, {
    title: 'titles.category_list',
    icon: "category"
  }, null, 8 /* PROPS */, ["title"]), _createElementVNode("ul", _hoisted_2, [_ctx.categories.length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList(_ctx.categories, category => {
    return _openBlock(), _createElementBlock("li", {
      class: "flex flex-row items-center hover:opacity-50",
      key: category.slug
    }, [_createElementVNode("span", _hoisted_3, _toDisplayString(category.name), 1 /* TEXT */), _createElementVNode("b", _hoisted_4, _toDisplayString(category.count), 1 /* TEXT */)]);
  }), 128 /* KEYED_FRAGMENT */)) : (_openBlock(), _createBlock(_component_ob_skeleton, {
    key: 1,
    tag: "li",
    count: 10,
    height: "20px",
    width: "3rem"
  }))])]);
}