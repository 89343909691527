import _defineProperty from "/home/runner/work/adminy.github.io/adminy.github.io/themes/aurora/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
export class ThemeConfig {
  /** Menu config data */

  /** Avatar config data */

  /** Theme config data */

  /** Site config data */

  /** Socials config data */

  /** Meta data for the site */

  /** Plugin configs */

  /** Theme version */

  /**
   * Model class for Hexo theme config
   *
   * @param raw Config data generated from Hexo
   */
  constructor(raw) {
    _defineProperty(this, "menu", new ThemeMenu());
    _defineProperty(this, "avatar", new Avatar());
    _defineProperty(this, "theme", new Theme());
    _defineProperty(this, "site", new Site());
    _defineProperty(this, "socials", new Social());
    _defineProperty(this, "site_meta", new SiteMeta());
    _defineProperty(this, "plugins", new Plugins());
    _defineProperty(this, "version", '');
    const rawConfig = raw && raw['theme_config'];
    if (rawConfig) {
      this.menu = new ThemeMenu(rawConfig.menu);
      this.avatar = new Avatar(rawConfig.avatar);
      this.theme = new Theme(rawConfig.theme);
      this.site = new Site(rawConfig.site);
      this.socials = new Social(rawConfig.socials);
      this.plugins = new Plugins(rawConfig);
      this.site_meta = new SiteMeta(rawConfig.site_meta);
      this.version = rawConfig.version;
    }
  }
}
export class ThemeMenu {
  /**
   * Model class for Hexo theme's menu set
   *
   * @param raw Config data generated from Hexo
   */
  constructor(raw) {
    _defineProperty(this, "menus", {
      Home: new Menu({
        name: 'Home',
        path: '/',
        i18n: {
          cn: '首页',
          en: 'Home'
        }
      })
    });
    const extract = {
      About: {
        name: 'About',
        path: '/page/about/',
        i18n: {
          cn: '关于',
          en: 'About'
        }
      },
      Archives: {
        name: 'Archives',
        path: '/archives',
        i18n: {
          cn: '归档',
          en: 'Archives'
        }
      },
      Tags: {
        name: 'Tags',
        path: '/tags',
        i18n: {
          cn: '标签',
          en: 'Tags'
        }
      }
    };
    const defaultMenus = Object.keys(extract);
    if (raw) {
      // Theme default menus
      for (const menu of defaultMenus) {
        if (typeof raw[menu] === 'boolean' && raw[menu]) {
          Object.assign(this.menus, {
            [menu]: new Menu(extract[menu])
          });
        }
      }
      // Theme custom menus
      for (const otherMenu of Object.keys(raw)) {
        if (defaultMenus.indexOf(otherMenu) < 0 && raw[otherMenu].name) {
          Object.assign(this.menus, {
            [otherMenu]: new Menu(raw[otherMenu])
          });
        }
      }
    }
  }
}
export class Menu {
  /** Name of the menu */

  /** Vue router path for the menu */

  /** Translation key for vue-i18n */

  /** Sub menus */

  /**
   * Model class for Hexo theme's menu
   *
   * @param raw Config data generated from Hexo
   */
  constructor(menu) {
    _defineProperty(this, "name", '');
    _defineProperty(this, "path", '');
    _defineProperty(this, "i18n", {});
    _defineProperty(this, "children", []);
    this.name = menu.name;
    this.path = menu.path ? menu.path : null;
    this.i18n = menu.i18n ? menu.i18n : {};
    this.children = menu.children ? Object.keys(menu.children).map(key => new Menu(menu.children[key])) : [];
  }
}
export class Avatar {
  /**
   * Model class for Avatar data
   *
   * @param raw - Config data generated from Hexo
   */
  constructor(raw) {
    _defineProperty(this, "source_path", '');
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          Object.assign(this, {
            [key]: raw[key]
          });
        }
      }
    }
  }
}
export class Theme {
  /**
   * Model class for Avatar data
   *
   * @param raw - Config data generated from Hexo
   */
  constructor(raw) {
    _defineProperty(this, "dark_mode", 'auto');
    _defineProperty(this, "profile_shape", 'diamond');
    _defineProperty(this, "feature", true);
    _defineProperty(this, "postEdit", {
      enable: true,
      url: 'https://github.com/adminy/adminy.github.io/tree/master/source/_posts/'
    });
    _defineProperty(this, "gradient", {
      color_1: '#24c6dc',
      color_2: '#5433ff',
      color_3: '#ff0099'
    });
    _defineProperty(this, "header_gradient_css", 'linear-gradient(130deg, #24c6dc, #5433ff 41.07%, #ff0099 76.05%)');
    _defineProperty(this, "background_gradient_style", {
      background: 'linear-gradient(130deg, #24c6dc, #5433ff 41.07%, #ff0099 76.05%)',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      '-webkit-box-decoration-break': 'clone',
      'box-decoration-break': 'clone'
    });
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          if (key === 'profile_shape') {
            const allowedShapes = ['circle', 'diamond', 'rounded'];
            const convertedClasses = ['circle-avatar', 'diamond-avatar', 'rounded-avatar'];
            const shadeIndex = allowedShapes.indexOf(raw[key]);
            if (shadeIndex < 0) raw[key] = convertedClasses[1];else raw[key] = convertedClasses[shadeIndex];
          }
          Object.assign(this, {
            [key]: raw[key]
          });
          if (key === 'gradient') {
            const headerGradientCss = `linear-gradient(130deg, ${this.gradient.color_1}, ${this.gradient.color_2} 41.07%, ${this.gradient.color_3} 76.05%)`;
            Object.assign(this, {
              header_gradient_css: headerGradientCss
            });
            Object.assign(this, {
              background_gradient_style: {
                background: headerGradientCss,
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
                '-webkit-box-decoration-break': 'clone',
                'box-decoration-break': 'clone'
              }
            });
          }
        }
      }
    }
  }
}
export class Social {
  /**
   * Model class for Social media links
   *
   * @param raw - Config data generated from Hexo
   */
  constructor(raw) {
    _defineProperty(this, "github", '');
    _defineProperty(this, "twitter", '');
    _defineProperty(this, "stackoverflow", '');
    _defineProperty(this, "wechat", '');
    _defineProperty(this, "qq", '');
    _defineProperty(this, "weibo", '');
    _defineProperty(this, "csdn", '');
    _defineProperty(this, "juejin", '');
    _defineProperty(this, "zhihu", '');
    _defineProperty(this, "customs", new CustomSocials());
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          if (key === 'customs') {
            Object.assign(this.customs, new CustomSocials(raw[key]));
          } else {
            Object.assign(this, {
              [key]: raw[key]
            });
          }
        }
      }
    }
  }
}
export class CustomSocial {
  constructor(raw) {
    _defineProperty(this, "icon", {
      iconfont: '',
      img_link: ''
    });
    _defineProperty(this, "link", '');
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          if (key === 'icon') {
            if (String(raw[key]).match(/([a-zA-Z0-9\s_\\.\-():])+(.svg|.png|.jpg)$/g)) {
              Object.assign(this.icon, {
                img_link: raw[key]
              });
            } else {
              Object.assign(this.icon, {
                iconfont: raw[key]
              });
            }
          } else {
            Object.assign(this, {
              [key]: raw[key]
            });
          }
        }
      }
    }
  }
}
export class CustomSocials {
  /**
   * Model class for Social media links
   *
   * @param raw - Config data generated from Hexo
   */
  constructor(raw) {
    _defineProperty(this, "socials", []);
    if (raw) {
      Object.assign(this.socials, Object.keys(raw).map(key => new CustomSocial(raw[key])));
    }
  }
}
export class Site {
  /** Website subtitle (used after `-`) */

  /** Author of the blog website */

  /** Author's nick name */

  /** Website description (used in the header meta tag) */

  /** Blog's default language */

  /** Allow use to change blog's locale */

  /** Site logo or brand logo */

  /** Author avatar */

  /** China server beian info */

  /** China server police beian info */

  /**
   * Model class for Site general settings
   *
   * @param raw - Config data generated from Hexo
   */
  constructor(raw) {
    _defineProperty(this, "subtitle", '');
    _defineProperty(this, "author", '');
    _defineProperty(this, "nick", '');
    _defineProperty(this, "description", '');
    _defineProperty(this, "language", 'en');
    _defineProperty(this, "multi_language", true);
    _defineProperty(this, "logo", '');
    _defineProperty(this, "avatar", '');
    _defineProperty(this, "beian", {
      number: '',
      link: ''
    });
    _defineProperty(this, "police_beian", {
      number: '',
      link: ''
    });
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          Object.assign(this, {
            [key]: raw[key]
          });
        }
      }
    }
  }
}
export class SiteMeta {
  /**
   * Model class for Site meta settings
   *
   * @param raw - Config data generated from Hexo
   */
  constructor(raw) {
    _defineProperty(this, "cdn", {
      locale: 'en',
      prismjs: []
    });
    _defineProperty(this, "favicon", '');
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          Object.assign(this, {
            [key]: raw[key]
          });
        }
      }
    }
  }
}
export class Plugins {
  /**
   * Model class for Site meta settings
   *
   * @param raw - Config data generated from Hexo
   */
  constructor(raw) {
    _defineProperty(this, "giscus", {
      enable: false,
      repo: '',
      repoId: '',
      category: '',
      categoryId: '',
      mapping: 'pathname',
      reactionsEnabled: 1,
      emitMetadata: 1,
      inputPosition: 'top',
      theme: 'dark',
      lang: 'en',
      crossorigin: 'anonymous',
      loading: 'lazy'
    });
    _defineProperty(this, "recent_comments", false);
    _defineProperty(this, "busuanzi", {
      enable: true
    });
    _defineProperty(this, "copy_protection", {
      enable: true,
      author: {
        cn: '',
        en: ''
      },
      link: {
        cn: '',
        en: ''
      },
      license: {
        cn: '',
        en: ''
      }
    });
    _defineProperty(this, "aurora_bot", {
      enable: false,
      locale: 'en',
      bot_type: 'dia',
      tips: {}
    });
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          Object.assign(this, {
            [key]: raw[key]
          });
        }
      }
    }
  }
}