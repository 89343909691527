/* eslint-disable @typescript-eslint/no-explicit-any*/
import request from '@/utils/request';
// GET /api/site.json
export async function fetchHexoConfig() {
  return request.get('/site.json');
}
// GET /api/posts/:pageNum.json (default 1.json)
export async function fetchPostsList(currentPage) {
  return request.get(`/posts/${currentPage}.json`);
}
// GET /api/tags/:TagName.json
export async function fetchPostsListByTag(tagName) {
  return request.get(`/tags/${tagName}.json`);
}
// GET /api/categories/:slug.json
export async function fetchPostsListByCategory(categoryName) {
  return request.get(`/categories/${categoryName}.json`);
}
// GET /api/articles/:Slug.json
export async function fetchPostBySlug(slug) {
  return request.get(`/articles/${slug}.json`);
}
// GET /api/tags.json
export async function fetchAllTags() {
  return request.get(`/tags.json`);
}
// GET /api/categories.json
export async function fetchAllCategories() {
  return request.get(`/categories.json`);
}
// GET /api/pages/about/index.json
// source : e.g. about/index
export async function fetchImplicitPageBySource(source) {
  return request.get(`/pages/${source}/index.json`);
}
// GET /api/features.json
export async function fetchFeature() {
  return request.get('/features.json');
}
// GET /api/statistic.json
export async function fetchStatistic() {
  return request.get('/statistic.json');
}
// GET /api/search.json
export async function fetchSearchIndexes() {
  return request.get('/search.json');
}
// GET /api/authors/author-slug.json
export async function fetchAuthorPost(slug) {
  return request.get(`/authors/${slug}.json`);
}