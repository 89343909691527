import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-18d805d9"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  id: "sidebar-navigator",
  class: "flex flex-row bg-ob-deep-800 rounded-xl shadow-2xl justify-items-center overflow-hidden"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_svg_icon = _resolveComponent("svg-icon");
  return _openBlock(), _createElementBlock("ul", _hoisted_1, [_createElementVNode("li", {
    class: "border-r-4 border-ob-deep-900 flex justify-center py-3 w-full hover:opacity-50 hover:text-ob transition-all cursor-pointer",
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.goBack && _ctx.goBack(...args))
  }, [_createVNode(_component_svg_icon, {
    class: "inline-block text-3xl",
    "icon-class": "go-back"
  })]), _createElementVNode("li", {
    class: "border-r-4 border-ob-deep-900 flex justify-center py-3 w-full hover:opacity-50 hover:text-ob transition-all cursor-pointer",
    onClick: _cache[1] || (_cache[1] =
    //@ts-ignore
    (...args) => _ctx.backToTop && _ctx.backToTop(...args))
  }, [_createVNode(_component_svg_icon, {
    class: "inline-block text-3xl",
    "icon-class": "back-to-top"
  })]), _createElementVNode("li", {
    class: "flex justify-center py-3 w-full hover:opacity-50 hover:text-ob transition-all cursor-pointer",
    onClick: _cache[2] || (_cache[2] =
    //@ts-ignore
    (...args) => _ctx.jumpToComments && _ctx.jumpToComments(...args)),
    "data-dia": "jump-to-comment"
  }, [_createVNode(_component_svg_icon, {
    class: "inline-block text-3xl",
    "icon-class": "quote"
  })])]);
}