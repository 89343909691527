import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-003488de"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "paginator"
};
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_svg_icon = _resolveComponent("svg-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("ul", null, [_ctx.currentPage > 1 ? (_openBlock(), _createElementBlock("li", {
    key: 0,
    class: "text-ob-bright",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.pageChangeEmitter(_ctx.currentPage - 1))
  }, [_createVNode(_component_svg_icon, {
    "icon-class": "arrow-left"
  }), _createTextVNode(" " + _toDisplayString(_ctx.t('settings.paginator.newer')), 1 /* TEXT */)])) : _createCommentVNode("v-if", true), _ctx.paginator.head !== 0 ? (_openBlock(), _createElementBlock("li", {
    key: 1,
    class: _normalizeClass({
      active: _ctx.currentPage === _ctx.paginator.head
    }),
    onClick: _cache[1] || (_cache[1] = $event => _ctx.pageChangeEmitter(_ctx.paginator.head))
  }, _toDisplayString(_ctx.paginator.head), 3 /* TEXT, CLASS */)) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginator.pages, (page, key) => {
    return _openBlock(), _createElementBlock("li", {
      key: key,
      class: _normalizeClass({
        active: _ctx.currentPage === page
      }),
      onClick: $event => _ctx.pageChangeEmitter(page)
    }, _toDisplayString(page), 11 /* TEXT, CLASS, PROPS */, _hoisted_2);
  }), 128 /* KEYED_FRAGMENT */)), _ctx.paginator.end !== 0 ? (_openBlock(), _createElementBlock("li", {
    key: 2,
    class: _normalizeClass({
      active: _ctx.currentPage === _ctx.paginator.end
    }),
    onClick: _cache[2] || (_cache[2] = $event => _ctx.pageChangeEmitter(_ctx.paginator.end))
  }, _toDisplayString(_ctx.paginator.end), 3 /* TEXT, CLASS */)) : _createCommentVNode("v-if", true), _ctx.currentPage < _ctx.pages ? (_openBlock(), _createElementBlock("li", {
    key: 3,
    class: "text-ob-bright",
    onClick: _cache[3] || (_cache[3] = $event => _ctx.pageChangeEmitter(_ctx.currentPage + 1))
  }, [_createTextVNode(_toDisplayString(_ctx.t('settings.paginator.older')) + " ", 1 /* TEXT */), _createVNode(_component_svg_icon, {
    "icon-class": "arrow-right"
  })])) : _createCommentVNode("v-if", true)])]);
}