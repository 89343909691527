import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useDiaStore } from '@/stores/dia';
import { useAppStore } from '@/stores/app';
export default defineComponent({
  name: 'AUDia',
  setup() {
    const diaStore = useDiaStore();
    const appStore = useAppStore();
    const showDia = ref(false);
    const initializeBot = () => {
      if (!appStore.themeConfig.plugins.aurora_bot.enable) return;
      diaStore.initializeBot({
        locale: appStore.themeConfig.plugins.aurora_bot.locale,
        tips: appStore.themeConfig.plugins.aurora_bot.tips
      });
      setTimeout(() => {
        showDia.value = true;
      }, 1000);
    };
    watch(() => appStore.configReady, value => {
      if (value) {
        initializeBot();
      }
    });
    onMounted(() => {
      if (appStore.configReady) initializeBot();
    });
    return {
      cssVariables: computed(() => {
        return `
          --aurora-dia--linear-gradient: ${appStore.themeConfig.theme.header_gradient_css};
          --aurora-dia--linear-gradient-hover: linear-gradient(
            to bottom,
            ${appStore.themeConfig.theme.gradient.color_2},
            ${appStore.themeConfig.theme.gradient.color_3}
          );
          --aurora-dia--platform-light: ${appStore.themeConfig.theme.gradient.color_3};
        `;
      }),
      isDark: computed(() => appStore.theme === 'theme-dark'),
      showDia
    };
  }
});