import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-68977eab"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ob-gradient-cut-plate absolute bg-ob-deep-900 rounded-xl opacity-90 flex justify-center items-center pt-4 px-6 shadow-lg hover:shadow-2xl duration-300",
  "data-dia": "author"
};
const _hoisted_2 = {
  class: "profile absolute w-full flex flex-col justify-center items-center"
};
const _hoisted_3 = {
  class: "flex flex-col justify-center items-center"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "text-center pt-4 text-4xl font-semibold text-ob-bright"
};
const _hoisted_6 = ["innerHTML"];
const _hoisted_7 = {
  key: 3,
  class: "pt-6 px-10 w-full text-sm text-center flex flex-col gap-2"
};
const _hoisted_8 = {
  class: "h-full w-full flex flex-col flex-1 justify-end items-end"
};
const _hoisted_9 = {
  class: "grid grid-cols-4 pt-4 w-full px-2 text-lg"
};
const _hoisted_10 = {
  class: "col-span-1 text-center"
};
const _hoisted_11 = {
  class: "text-ob-bright"
};
const _hoisted_12 = {
  class: "text-base"
};
const _hoisted_13 = {
  class: "col-span-1 text-center"
};
const _hoisted_14 = {
  class: "text-ob-bright"
};
const _hoisted_15 = {
  class: "text-base"
};
const _hoisted_16 = {
  class: "col-span-1 text-center"
};
const _hoisted_17 = {
  class: "text-ob-bright"
};
const _hoisted_18 = {
  class: "text-base"
};
const _hoisted_19 = {
  class: "col-span-1 text-center"
};
const _hoisted_20 = {
  class: "text-ob-bright"
};
const _hoisted_21 = {
  class: "text-base"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ob_skeleton = _resolveComponent("ob-skeleton");
  const _component_Social = _resolveComponent("Social");
  return _openBlock(), _createElementBlock("div", {
    class: "h-98 w-full rounded-2xl relative shadow-xl mb-8",
    style: _normalizeStyle(_ctx.gradientBackground)
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_ctx.authorData.avatar !== '' ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    class: _normalizeClass(_ctx.avatarClass),
    src: _ctx.authorData.avatar,
    alt: "avatar"
  }, null, 10 /* CLASS, PROPS */, _hoisted_4)) : (_openBlock(), _createBlock(_component_ob_skeleton, {
    key: 1,
    width: "7rem",
    height: "7rem",
    circle: ""
  })), _createElementVNode("h2", _hoisted_5, [_ctx.authorData.name ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createTextVNode(_toDisplayString(_ctx.authorData.name), 1 /* TEXT */)], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createBlock(_component_ob_skeleton, {
    key: 1,
    height: "2.25rem",
    width: "7rem"
  }))]), _createElementVNode("span", {
    class: "h-1 w-14 rounded-full mt-2",
    style: _normalizeStyle(_ctx.gradientBackground)
  }, null, 4 /* STYLE */), _ctx.authorData.description ? (_openBlock(), _createElementBlock("p", {
    key: 2,
    class: "pt-6 px-10 w-full text-sm text-center",
    innerHTML: _ctx.authorData.description
  }, null, 8 /* PROPS */, _hoisted_6)) : (_openBlock(), _createElementBlock("p", _hoisted_7, [_createVNode(_component_ob_skeleton, {
    count: 2,
    height: "20px",
    width: "10rem"
  })]))]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_Social, {
    socials: _ctx.authorData.socials
  }, null, 8 /* PROPS */, ["socials"]), _createElementVNode("ul", _hoisted_9, [_createElementVNode("li", _hoisted_10, [_createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.authorData.word_count), 1 /* TEXT */), _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.t('settings.words')), 1 /* TEXT */)]), _createElementVNode("li", _hoisted_13, [_createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.authorData.post_list.length), 1 /* TEXT */), _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.t('settings.articles')), 1 /* TEXT */)]), _createElementVNode("li", _hoisted_16, [_createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.authorData.categories), 1 /* TEXT */), _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.t('settings.categories')), 1 /* TEXT */)]), _createElementVNode("li", _hoisted_19, [_createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.authorData.tags), 1 /* TEXT */), _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.t('settings.tags')), 1 /* TEXT */)])])])])])], 4 /* STYLE */);
}