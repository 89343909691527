import { computed, defineComponent, onBeforeMount, onMounted, onUnmounted, ref, watch } from 'vue';
import { useAppStore } from '@/stores/app';
import { useCommonStore } from '@/stores/common';
import { useMetaStore } from '@/stores/meta';
import { useSearchStore } from './stores/search';
import HeaderMain from '@/components/Header/src/Header.vue';
import Footer from '@/components/Footer.vue';
import Navigator from '@/components/Navigator.vue';
import MobileMenu from '@/components/MobileMenu.vue';
import Dia from '@/components/Dia.vue';
export default defineComponent({
  name: 'App',
  components: {
    HeaderMain,
    Footer,
    Navigator,
    MobileMenu,
    Dia
  },
  setup() {
    const appStore = useAppStore();
    const commonStore = useCommonStore();
    const metaStore = useMetaStore();
    const searchStore = useSearchStore();
    const MOBILE_WITH = 996; // Using the mobile width by Bootstrap design.
    const appWrapperClass = 'app-wrapper';
    const loadingBarClass = ref({
      'nprogress-custom-parent': false
    });
    let pagelink = `\n\nRead more at: ${document.location.href}`;
    /** Intiallizing App config and other setups */
    const initialApp = async () => {
      initResizeEvent();
      await appStore.fetchConfig().then(() => {
        metaStore.addScripts(appStore.themeConfig.site_meta.cdn.prismjs);
        // Change the favicon dynamically.
        if (appStore.themeConfig.site_meta.favicon && appStore.themeConfig.site_meta.favicon !== '') {
          const link = document.querySelector("link[rel~='icon']");
          if (link) link.setAttribute('href', appStore.themeConfig.site_meta.favicon);
        }
        if (appStore.themeConfig.plugins.copy_protection.enable) {
          const locale = appStore.locale;
          const linkPlaceholder = locale === 'cn' ? appStore.themeConfig.plugins.copy_protection.link.cn : appStore.themeConfig.plugins.copy_protection.link.en;
          const authorPlaceholder = locale === 'cn' ? appStore.themeConfig.plugins.copy_protection.author.cn : appStore.themeConfig.plugins.copy_protection.author.en;
          const licensePlaceholder = locale === 'cn' ? appStore.themeConfig.plugins.copy_protection.license.cn : appStore.themeConfig.plugins.copy_protection.license.en;
          pagelink = `\n\n---------------------------------\n${authorPlaceholder}: ${appStore.themeConfig.site.author}\n${linkPlaceholder}: ${document.location.href}\n${licensePlaceholder}`;
          intialCopyrightScript();
        }
      });
    };
    const copyEventHandler = event => {
      if (document.getSelection() instanceof Selection) {
        if (document.getSelection()?.toString() !== '' && event.clipboardData) {
          event.clipboardData.setData('text', document.getSelection() + pagelink);
          event.preventDefault();
        }
      }
    };
    /** Adding copy listner */
    const intialCopyrightScript = () => {
      document.addEventListener('copy', copyEventHandler);
    };
    const isMobile = computed(() => {
      return commonStore.isMobile;
    });
    const resizeHanler = () => {
      const rect = document.body.getBoundingClientRect();
      const mobileState = rect.width - 1 < MOBILE_WITH;
      if (isMobile.value !== mobileState) commonStore.changeMobileState(mobileState);
    };
    const initResizeEvent = () => {
      resizeHanler();
      window.addEventListener('resize', resizeHanler);
    };
    const handleOpenModal = () => {
      searchStore.setOpenModal(true);
    };
    onBeforeMount(initialApp);
    onUnmounted(() => {
      document.removeEventListener('copy', copyEventHandler);
      window.removeEventListener('resize', resizeHanler);
    });
    const wrapperStyle = ref({
      'min-height': '100vh'
    });
    onMounted(() => {
      let wrapperHeight = screen.height;
      const footerEl = document.getElementById('footer');
      const footerHeight = footerEl?.getBoundingClientRect().height;
      if (typeof footerHeight === 'number') {
        wrapperHeight = wrapperHeight - footerHeight * 2;
      }
      wrapperStyle.value = {
        'min-height': wrapperHeight + 'px'
      };
    });
    /**
     * Watches the app loading status, adding the `nprogress-custom-parent`
     * class to the nprogress container when loading.
     */
    watch(() => appStore.appLoading, newState => {
      loadingBarClass.value['nprogress-custom-parent'] = newState;
    });
    return {
      title: computed(() => metaStore.getTitle),
      theme: computed(() => appStore.theme),
      scripts: computed(() => metaStore.scripts),
      themeConfig: computed(() => appStore.themeConfig),
      headerImage: computed(() => {
        return {
          backgroundImage: `url(${commonStore.headerImage}), url(${require('@/assets/default-cover.jpg')})`,
          opacity: commonStore.headerImage !== '' ? 1 : 0
        };
      }),
      headerBaseBackground: computed(() => {
        return {
          background: appStore.themeConfig.theme.header_gradient_css,
          opacity: commonStore.headerImage !== '' ? 0.91 : 0.99
        };
      }),
      wrapperStyle: computed(() => wrapperStyle.value),
      handleEscKey: appStore.handleEscKey,
      isMobile: computed(() => commonStore.isMobile),
      configReady: computed(() => appStore.configReady),
      cssVariables: computed(() => {
        if (appStore.theme === 'theme-dark') {
          return `
            --text-accent: ${appStore.themeConfig.theme.gradient.color_1};
            --text-sub-accent: ${appStore.themeConfig.theme.gradient.color_3};
            --main-gradient: ${appStore.themeConfig.theme.header_gradient_css};
          `;
        }
        return `
          --text-accent: ${appStore.themeConfig.theme.gradient.color_3};
          --text-sub-accent: ${appStore.themeConfig.theme.gradient.color_2};
          --main-gradient: ${appStore.themeConfig.theme.header_gradient_css};
        `;
      }),
      appWrapperClass,
      loadingBarClass,
      handleOpenModal
    };
  }
});