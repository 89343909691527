import _defineProperty from "/home/runner/work/adminy.github.io/adminy.github.io/themes/aurora/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
// https://hexo.io/zh-cn/docs/configuration.html
export class HexoConfig {
  constructor(raw) {
    _defineProperty(this, "site", new Site());
    _defineProperty(this, "url", new URL());
    _defineProperty(this, "directory", new Directory());
    _defineProperty(this, "writing", new Writing());
    _defineProperty(this, "categoriesAndTags", new CategoryAndTags());
    _defineProperty(this, "dateTimeFormat", new DateTimeFormat());
    _defineProperty(this, "page", new Pagination());
    _defineProperty(this, "extensions", new Extensions());
    if (raw) {
      this.site = new Site(raw);
      this.url = new URL(raw);
      this.directory = new Directory(raw);
      this.writing = new Writing(raw);
      this.categoriesAndTags = new CategoryAndTags(raw);
      this.dateTimeFormat = new DateTimeFormat(raw);
      this.page = new Pagination(raw);
      this.extensions = new Extensions(raw);
    }
  }
}
export class Site {
  // 网站标题
  // 网站副标题
  // 网站描述
  // 您的名字
  // 网站使用的语言
  //网站时区。Hexo 默认使用您电脑的时区。时区列表。比如说：America/New_York, Japan, 和 UTC 。
  constructor(raw) {
    _defineProperty(this, "title", '');
    _defineProperty(this, "subtitle", '');
    _defineProperty(this, "description", '');
    _defineProperty(this, "author", '');
    _defineProperty(this, "language", '');
    _defineProperty(this, "timezone", '');
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          Object.assign(this, {
            [key]: raw[key]
          });
        }
      }
    }
  }
}
export class URL {
  // 网址
  // 网站根目录
  // 文章的 永久链接 格式
  // 永久链接中各部分的默认值
  constructor(raw) {
    _defineProperty(this, "url", '');
    _defineProperty(this, "root", '');
    _defineProperty(this, "permalink", '');
    _defineProperty(this, "permalink_defaults", '');
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          Object.assign(this, {
            [key]: raw[key]
          });
        }
      }
    }
  }
}
export class Directory {
  // 资源文件夹，这个文件夹用来存放内容。
  // 公共文件夹，这个文件夹用于存放生成的站点文件。
  // 标签文件夹
  // 归档文件夹
  // 分类文件夹
  // Include code 文件夹
  // 国际化（i18n）文件夹
  // 跳过指定文件的渲染，您可使用 glob 表达式来匹配路径。
  constructor(raw) {
    _defineProperty(this, "source_dir", '');
    _defineProperty(this, "public_dir", '');
    _defineProperty(this, "tag_dir", '');
    _defineProperty(this, "archive_dir", '');
    _defineProperty(this, "category_dir", '');
    _defineProperty(this, "code_dir", '');
    _defineProperty(this, "i18n_dir", '');
    _defineProperty(this, "skip_render", '');
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          Object.assign(this, {
            [key]: raw[key]
          });
        }
      }
    }
  }
}
export class Writing {
  // 新文章的文件名称
  // 预设布局
  // 把标题转换为 title case
  // 把文件名称转换为 (1) 小写或 (2) 大写
  // 在新标签中打开链接
  // 显示草稿
  // 启动 Asset 文件夹
  // 把链接改为与根目录的相对位址
  // 显示未来的文章

  // 代码块的设置

  constructor(raw) {
    _defineProperty(this, "new_post_name", '');
    _defineProperty(this, "default_layout", '');
    _defineProperty(this, "titlecase", false);
    _defineProperty(this, "filename_case", 0);
    _defineProperty(this, "external_link", '');
    _defineProperty(this, "render_drafts", false);
    _defineProperty(this, "post_asset_folder", false);
    _defineProperty(this, "relative_link", false);
    _defineProperty(this, "future", true);
    _defineProperty(this, "highlight", {
      enable: false,
      line_number: true,
      auto_detect: false,
      tab_replace: ''
    });
    _defineProperty(this, "prismjs", {
      enable: true,
      preprocess: false,
      line_number: true,
      tab_replace: ''
    });
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          Object.assign(this, {
            [key]: raw[key]
          });
        }
      }
    }
  }
}
export class CategoryAndTags {
  // 默认分类
  // 分类别名
  // 标签别名
  constructor(raw) {
    _defineProperty(this, "default_category", '');
    _defineProperty(this, "category_map", '');
    _defineProperty(this, "tag_map", '');
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          Object.assign(this, {
            [key]: raw[key]
          });
        }
      }
    }
  }
}
export class DateTimeFormat {
  // 日期格式	YYYY-MM-DD
  // 时间格式	H:mm:ss
  constructor(raw) {
    _defineProperty(this, "date_format", '');
    _defineProperty(this, "time_format", '');
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          Object.assign(this, {
            [key]: raw[key]
          });
        }
      }
    }
  }
}
export class Pagination {
  // 每页显示的文章量 (0 = 关闭分页功能)
  // 分页目录
  constructor(raw) {
    _defineProperty(this, "per_page", 0);
    _defineProperty(this, "pagination_dir", '');
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          Object.assign(this, {
            [key]: raw[key]
          });
        }
      }
    }
  }
}
export class Extensions {
  // 当前主题名称。值为false时禁用主题
  // 部署部分的设置
  constructor(raw) {
    _defineProperty(this, "theme", false);
    _defineProperty(this, "deploy", {});
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          Object.assign(this, {
            [key]: raw[key]
          });
        }
      }
    }
  }
}