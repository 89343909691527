import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vShow as _vShow, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  id: "sticky-sidebar"
};
const _hoisted_2 = {
  class: "sidebar-box mb-4"
};
const _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SubTitle = _resolveComponent("SubTitle");
  const _component_Navigator = _resolveComponent("Navigator");
  const _component_Sticky = _resolveComponent("Sticky");
  const _directive_scroll_spy_active = _resolveDirective("scroll-spy-active");
  const _directive_scroll_spy_link = _resolveDirective("scroll-spy-link");
  return _openBlock(), _createBlock(_component_Sticky, {
    stickyTop: 32,
    endingElId: "footer",
    dynamicElClass: "#sticky-sidebar"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_Transition, {
      name: "fade-slide-y",
      mode: "out-in",
      persisted: ""
    }, {
      default: _withCtx(() => [_withDirectives(_createElementVNode("div", _hoisted_2, [_createVNode(_component_SubTitle, {
        title: 'titles.toc',
        icon: "toc"
      }, null, 8 /* PROPS */, ["title"]), _withDirectives(_createElementVNode("div", {
        innerHTML: _ctx.tocData,
        style: _normalizeStyle(_ctx.sideBoxStyle)
      }, null, 12 /* STYLE, PROPS */, _hoisted_3), [[_directive_scroll_spy_active, {
        selector: '.toc-item'
      }], [_directive_scroll_spy_link]])], 512 /* NEED_PATCH */), [[_vShow, _ctx.showToc]])]),
      _: 1 /* STABLE */
    }), _createVNode(_component_Navigator)])]),
    _: 1 /* STABLE */
  });
}