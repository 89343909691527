import { computed, defineComponent, inject } from 'vue';
export default defineComponent({
  name: 'ObDropdownMenu',
  props: {
    expand: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const sharedState = inject('sharedState', {
      active: false
    });
    const active = computed(() => sharedState.active);
    return {
      active
    };
  }
});