import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, withKeys as _withKeys, Teleport as _Teleport, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1fef7763"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ob-drop-shadow",
  "data-dia": "language"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  "no-hover-effect": "",
  class: "ob-drop-shadow",
  "data-dia": "light-switch"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_svg_icon = _resolveComponent("svg-icon");
  const _component_DropdownItem = _resolveComponent("DropdownItem");
  const _component_DropdownMenu = _resolveComponent("DropdownMenu");
  const _component_Dropdown = _resolveComponent("Dropdown");
  const _component_ThemeToggle = _resolveComponent("ThemeToggle");
  const _component_SearchModal = _resolveComponent("SearchModal");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
    class: "header-controls absolute top-10 right-0 flex flex-row",
    onKeydown: _cache[1] || (_cache[1] = _withKeys($event => _ctx.handleOpenModal(true), ["k"])),
    tabindex: "0"
  }, [_createElementVNode("span", {
    class: "ob-drop-shadow",
    "data-dia": "search",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.handleOpenModal(true))
  }, [_createVNode(_component_svg_icon, {
    "icon-class": "search"
  })]), _ctx.enableMultiLanguage ? (_openBlock(), _createBlock(_component_Dropdown, {
    key: 0,
    onCommand: _ctx.handleClick
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_svg_icon, {
      "icon-class": "globe"
    }), _ctx.$i18n.locale == 'cn' ? (_openBlock(), _createElementBlock("span", _hoisted_2, "中文")) : _createCommentVNode("v-if", true), _ctx.$i18n.locale == 'en' ? (_openBlock(), _createElementBlock("span", _hoisted_3, "EN")) : _createCommentVNode("v-if", true)]), _createVNode(_component_DropdownMenu, null, {
      default: _withCtx(() => [_createVNode(_component_DropdownItem, {
        name: "en"
      }, {
        default: _withCtx(() => [_createTextVNode("English")]),
        _: 1 /* STABLE */
      }), _createVNode(_component_DropdownItem, {
        name: "cn"
      }, {
        default: _withCtx(() => [_createTextVNode("中文")]),
        _: 1 /* STABLE */
      })]),

      _: 1 /* STABLE */
    })]),

    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onCommand"])) : _createCommentVNode("v-if", true), _createElementVNode("span", _hoisted_4, [_createVNode(_component_ThemeToggle)])], 32 /* HYDRATE_EVENTS */), (_openBlock(), _createBlock(_Teleport, {
    to: "body"
  }, [_createVNode(_component_SearchModal)]))], 64 /* STABLE_FRAGMENT */);
}