import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "inverted-main-grid py-8 gap-8 box-border"
};
const _hoisted_2 = {
  class: "relative overflow-hidden h-56 lg:h-auto rounded-2xl bg-ob-deep-800 shadow-lg"
};
const _hoisted_3 = {
  class: "ob-gradient-plate opacity-90 relative z-10 bg-ob-deep-900 rounded-2xl flex justify-start items-end px-8 pb-10 shadow-md"
};
const _hoisted_4 = {
  class: "text-3xl pb-8 lg:pb-16"
};
const _hoisted_5 = {
  class: "relative text-2xl text-ob-bright font-semibold"
};
const _hoisted_6 = {
  class: "grid lg:grid-cols-2 gap-8"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_svg_icon = _resolveComponent("svg-icon");
  const _component_Article = _resolveComponent("Article");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("h2", _hoisted_4, [_createElementVNode("p", {
    style: _normalizeStyle(_ctx.gradientText)
  }, "EDITOR'S SELECTION", 4 /* STYLE */), _createElementVNode("span", _hoisted_5, [_createVNode(_component_svg_icon, {
    class: "inline-block",
    "icon-class": "hot"
  }), _createTextVNode(" " + _toDisplayString(_ctx.t('home.recommended')), 1 /* TEXT */)])])]), _createElementVNode("span", {
    class: "absolute top-0 w-full h-full z-0",
    style: _normalizeStyle(_ctx.gradientBackground)
  }, null, 4 /* STYLE */)]), _createElementVNode("ul", _hoisted_6, [_ctx.featurePosts.length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList(_ctx.featurePosts, post => {
    return _openBlock(), _createElementBlock("li", {
      key: post.slug
    }, [_createVNode(_component_Article, {
      data: post
    }, null, 8 /* PROPS */, ["data"])]);
  }), 128 /* KEYED_FRAGMENT */)) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, _renderList(2, n => {
    return _createElementVNode("li", {
      key: n
    }, [_createVNode(_component_Article, {
      data: {}
    })]);
  }), 64 /* STABLE_FRAGMENT */))])]);
}