import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'ObSticky',
  props: {
    stickyTop: {
      type: Number,
      default: 0
    },
    zIndex: {
      type: Number,
      default: 1
    },
    className: {
      type: String,
      default: ''
    },
    stickyBottom: {
      type: Number,
      default: 0
    },
    endingElId: {
      type: String,
      default: ''
    },
    dynamicElClass: {
      type: String,
      default: ''
    }
  },
  setup() {
    const active = ref(false),
      position = ref(''),
      width = ref(),
      height = ref(),
      isSticky = ref(false),
      newTop = ref(0),
      top = ref(0),
      isBottom = ref(false);
    return {
      active,
      position,
      width,
      height,
      isSticky,
      newTop,
      top,
      isBottom
    };
  },
  mounted() {
    this.height = this.$el.getBoundingClientRect().height;
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  },
  activated() {
    this.handleScroll();
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    sticky(top, position) {
      if (this.active) {
        return;
      }
      this.top = top;
      this.position = position;
      this.active = true;
      this.width = this.width + 'px';
      this.isSticky = true;
    },
    handleReset() {
      if (!this.active) {
        return;
      }
      this.reset();
    },
    reset() {
      this.position = '';
      this.width = 'auto';
      this.active = false;
      this.isSticky = false;
    },
    handleScroll() {
      /**
       * setTimeout is added due to the warning of
       * "This site appears to use a scroll-linked
       * positioning effect. This may not work well
       * with asynchronous panning; " (On FireFox)
       */
      setTimeout(() => {
        const documentHeight = document.documentElement.scrollHeight;
        const width = this.$el.getBoundingClientRect().width;
        const height = this.$el.getBoundingClientRect().height;
        // Use `Dynamic Element Class` when your content will change
        // which will affect the height of your fixed container
        // this will update the height of your fixed container
        if (this.dynamicElClass !== '') {
          const dynamicEl = this.$el.querySelector(this.dynamicElClass);
          this.height = dynamicEl.getBoundingClientRect().height || height;
        }
        const scrollTop = window.scrollY;
        this.width = width || 'auto';
        const offsetTop = this.$el.getBoundingClientRect().top;
        // When the fixed container reaches the ending element container
        // Fix position property will be turned off, and the fixed container
        // will stop right before the ending element.
        const endingEl = this.endingElId !== '' ? document.getElementById(this.endingElId) : null;
        const wrapperEl = document.getElementById('App-Wrapper');
        const endingElSpacing = parseInt(window.getComputedStyle(wrapperEl || document.documentElement).paddingBottom, 10);
        const endingPos = endingEl && endingEl instanceof HTMLElement ? documentHeight - scrollTop - height - this.stickyTop - this.stickyBottom - endingEl.getBoundingClientRect().height - endingElSpacing : documentHeight;
        if (offsetTop < this.stickyTop) {
          this.active = false;
          if (endingPos <= 0) {
            this.isBottom = true;
            this.sticky(-1, 'absolute');
          } else {
            this.isBottom = false;
            this.sticky(this.stickyTop, 'fixed');
          }
          return;
        }
        this.handleReset();
      }, 16);
    },
    handleResize() {
      if (this.isSticky) {
        this.width = this.$el.getBoundingClientRect().width + 'px';
      }
    }
  }
});