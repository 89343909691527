import _defineProperty from "/home/runner/work/adminy.github.io/adminy.github.io/themes/aurora/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
export class Statistic {
  constructor(raw) {
    _defineProperty(this, "categories", 0);
    _defineProperty(this, "posts", 0);
    _defineProperty(this, "tags", 0);
    _defineProperty(this, "wordCount", 0);
    if (raw) {
      for (const key of Object.keys(this)) {
        if (Object.prototype.hasOwnProperty.call(raw, key)) {
          Object.assign(this, {
            [key]: raw[key]
          });
        }
      }
    }
  }
}