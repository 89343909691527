import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "sidebar-box"
};
const _hoisted_2 = {
  class: "flex flex-row items-center hover:opacity-50 mr-2 mb-2 cursor-pointer transition-all"
};
const _hoisted_3 = {
  class: "text-center px-3 py-1 rounded-md text-sm"
};
const _hoisted_4 = {
  class: "border-b-2 border-ob hover:text-ob"
};
const _hoisted_5 = {
  key: 2,
  class: "flex flex-row justify-center items-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SubTitle = _resolveComponent("SubTitle");
  const _component_TagItem = _resolveComponent("TagItem");
  const _component_router_link = _resolveComponent("router-link");
  const _component_ob_skeleton = _resolveComponent("ob-skeleton");
  const _component_svg_icon = _resolveComponent("svg-icon");
  const _component_TagList = _resolveComponent("TagList");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_SubTitle, {
    title: 'titles.tag_list',
    icon: "tag"
  }, null, 8 /* PROPS */, ["title"]), _createVNode(_component_TagList, null, {
    default: _withCtx(() => [_ctx.tags && _ctx.tags.length > 0 ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, tag => {
      return _openBlock(), _createBlock(_component_TagItem, {
        key: tag.slug,
        name: tag.name,
        slug: tag.slug,
        count: tag.count,
        size: "xs"
      }, null, 8 /* PROPS */, ["name", "slug", "count"]);
    }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, [_createElementVNode("b", _hoisted_4, [_createVNode(_component_router_link, {
      to: "/tags"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.t('settings.more-tags')) + " ... ", 1 /* TEXT */)]),

      _: 1 /* STABLE */
    })])])])], 64 /* STABLE_FRAGMENT */)) : _ctx.tags ? (_openBlock(), _createBlock(_component_ob_skeleton, {
      key: 1,
      tag: "li",
      count: 10,
      height: "20px",
      width: "3rem"
    })) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_svg_icon, {
      class: "stroke-ob-bright mr-2",
      "icon-class": "warning"
    }), _createTextVNode(" " + _toDisplayString(_ctx.t('settings.empty-tag')), 1 /* TEXT */)]))]),

    _: 1 /* STABLE */
  })]);
}