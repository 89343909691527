import { computed, defineComponent, onMounted } from 'vue';
import { SubTitle } from '@/components/Title';
import { useTagStore } from '@/stores/tag';
import { TagList, TagItem } from '@/components/Tag';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'ObTag',
  components: {
    SubTitle,
    TagList,
    TagItem
  },
  setup() {
    const tagStore = useTagStore();
    const {
      t
    } = useI18n();
    const fetchData = async () => {
      tagStore.fetchTagsByCount(10);
    };
    onMounted(fetchData);
    return {
      tags: computed(() => {
        if (tagStore.isLoaded && tagStore.tags.length === 0) return null;
        return tagStore.tags;
      }),
      t
    };
  }
});