import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Teleport as _Teleport, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "relative z-10"
};
const _hoisted_2 = {
  key: 0,
  class: "App-Mobile-sidebar"
};
const _hoisted_3 = {
  id: "App-Mobile-Profile",
  class: "App-Mobile-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_HeaderMain = _resolveComponent("HeaderMain");
  const _component_router_view = _resolveComponent("router-view");
  const _component_Footer = _resolveComponent("Footer");
  const _component_MobileMenu = _resolveComponent("MobileMenu");
  const _component_Navigator = _resolveComponent("Navigator");
  const _component_Dia = _resolveComponent("Dia");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
    id: "App-Wrapper",
    class: _normalizeClass([_ctx.appWrapperClass, _ctx.theme]),
    style: _normalizeStyle(_ctx.wrapperStyle)
  }, [_createElementVNode("div", {
    id: "App-Container",
    class: "app-container max-w-10/12 lg:max-w-screen-2xl px-3 lg:px-8",
    onKeydown: _cache[0] || (_cache[0] = _withKeys(_withModifiers(
    //@ts-ignore
    (...args) => _ctx.handleOpenModal && _ctx.handleOpenModal(...args), ["meta", "stop", "prevent"]), ["k"])),
    tabindex: "-1",
    style: _normalizeStyle(_ctx.cssVariables)
  }, [_createVNode(_component_HeaderMain), _createElementVNode("div", {
    class: "app-banner app-banner-image",
    style: _normalizeStyle(_ctx.headerImage)
  }, null, 4 /* STYLE */), _createElementVNode("div", {
    class: "app-banner app-banner-screen",
    style: _normalizeStyle(_ctx.headerBaseBackground)
  }, null, 4 /* STYLE */), _createElementVNode("div", _hoisted_1, [_createVNode(_component_router_view, null, {
    default: _withCtx(({
      Component
    }) => [_createVNode(_Transition, {
      name: "fade-slide-y",
      mode: "out-in"
    }, {
      default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))]),
      _: 2 /* DYNAMIC */
    }, 1024 /* DYNAMIC_SLOTS */)]),

    _: 1 /* STABLE */
  })])], 36 /* STYLE, HYDRATE_EVENTS */), _createElementVNode("div", {
    id: "loading-bar-wrapper",
    class: _normalizeClass(_ctx.loadingBarClass)
  }, null, 2 /* CLASS */)], 6 /* CLASS, STYLE */), _createVNode(_component_Footer, {
    style: _normalizeStyle(_ctx.cssVariables)
  }, null, 8 /* PROPS */, ["style"]), _ctx.isMobile ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_MobileMenu)])])) : _createCommentVNode("v-if", true), _createVNode(_component_Navigator), !_ctx.isMobile && _ctx.configReady ? (_openBlock(), _createBlock(_component_Dia, {
    key: 1
  })) : _createCommentVNode("v-if", true), (_openBlock(), _createBlock(_Teleport, {
    to: "head"
  }, [_createElementVNode("title", null, _toDisplayString(_ctx.title), 1 /* TEXT */)]))], 64 /* STABLE_FRAGMENT */);
}