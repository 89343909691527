import { defineComponent } from 'vue';
import { useDropdownStore } from '@/stores/dropdown';
export default defineComponent({
  name: 'ObDropdownItem',
  props: {
    name: String
  },
  setup(props) {
    const dropdownStore = useDropdownStore();
    const handleClick = () => {
      dropdownStore.setCommand(String(props.name));
    };
    return {
      handleClick
    };
  }
});