import { useAppStore } from '@/stores/app';
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'ObFeatureList',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const appStore = useAppStore();
    const {
      t
    } = useI18n();
    const handleAuthorClick = link => {
      if (link === '') link = window.location.href;
      window.location.href = link;
    };
    return {
      gradientBackground: computed(() => {
        return {
          background: appStore.themeConfig.theme.header_gradient_css
        };
      }),
      post: computed(() => props.data),
      handleAuthorClick,
      t
    };
  }
});