import "core-js/modules/es.array.push.js";
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useAppStore } from '@/stores/app';
import { Dropdown, DropdownMenu, DropdownItem } from '@/components/Dropdown';
import { isExternal } from '@/utils/validate';
export default defineComponent({
  name: 'Navigation',
  components: {
    Dropdown,
    DropdownMenu,
    DropdownItem
  },
  setup() {
    const {
      t,
      te
    } = useI18n();
    const router = useRouter();
    const appStore = useAppStore();
    const pushPage = path => {
      if (!path) return;
      if (isExternal(path)) {
        window.location.href = path;
      } else {
        router.push({
          path: path
        });
      }
    };
    return {
      routes: computed(() => appStore.themeConfig.menu.menus),
      pushPage,
      te,
      t
    };
  }
});