import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-64856a81"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "items-center flex-1 hidden lg:flex"
};
const _hoisted_2 = {
  class: "flex flex-row list-none px-6 text-white"
};
const _hoisted_3 = ["onClick", "data-menu"];
const _hoisted_4 = {
  key: 0,
  class: "relative z-50"
};
const _hoisted_5 = {
  key: 1,
  class: "relative z-50"
};
const _hoisted_6 = {
  key: 2,
  class: "relative z-50"
};
const _hoisted_7 = {
  key: 0,
  class: "relative z-50"
};
const _hoisted_8 = {
  key: 1,
  class: "relative z-50"
};
const _hoisted_9 = {
  key: 2,
  class: "relative z-50"
};
const _hoisted_10 = {
  key: 0,
  class: "relative z-50"
};
const _hoisted_11 = {
  key: 1,
  class: "relative z-50"
};
const _hoisted_12 = {
  key: 2,
  class: "relative z-50"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DropdownItem = _resolveComponent("DropdownItem");
  const _component_DropdownMenu = _resolveComponent("DropdownMenu");
  const _component_Dropdown = _resolveComponent("Dropdown");
  return _openBlock(), _createElementBlock("nav", _hoisted_1, [_createElementVNode("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, route => {
    return _openBlock(), _createElementBlock("li", {
      class: "not-italic font-medium text-xs h-full relative flex flex-col items-center justify-center cursor-pointer text-center py-4 px-2",
      key: route.path
    }, [route.children && route.children.length === 0 ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      class: "nav-link text-sm block px-1.5 py-0.5 rounded-md relative uppercase cursor-pointer",
      onClick: $event => _ctx.pushPage(route.path),
      "data-menu": route.name
    }, [_ctx.$i18n.locale === 'cn' && route.i18n.cn ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(route.i18n.cn), 1 /* TEXT */)) : _ctx.$i18n.locale === 'en' && route.i18n.en ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(route.i18n.en), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(route.name), 1 /* TEXT */))], 8 /* PROPS */, _hoisted_3)) : (_openBlock(), _createBlock(_component_Dropdown, {
      key: 1,
      onCommand: _ctx.pushPage,
      hover: "",
      class: "nav-link text-sm block px-1.5 py-0.5 rounded-md relative uppercase"
    }, {
      default: _withCtx(() => [_ctx.$i18n.locale === 'cn' && route.i18n.cn ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(route.i18n.cn), 1 /* TEXT */)) : _ctx.$i18n.locale === 'en' && route.i18n.en ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(route.i18n.en), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(route.name), 1 /* TEXT */)), _createVNode(_component_DropdownMenu, null, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(route.children, sub => {
          return _openBlock(), _createBlock(_component_DropdownItem, {
            key: sub.path,
            name: sub.path
          }, {
            default: _withCtx(() => [_ctx.$i18n.locale === 'cn' && sub.i18n.cn ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(sub.i18n.cn), 1 /* TEXT */)) : _ctx.$i18n.locale === 'en' && sub.i18n.en ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(sub.i18n.en), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(sub.name), 1 /* TEXT */))]),

            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["name"]);
        }), 128 /* KEYED_FRAGMENT */))]),

        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */)]),

      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onCommand"]))]);
  }), 128 /* KEYED_FRAGMENT */))])]);
}