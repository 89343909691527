import { defineComponent } from 'vue';
import { Logo, Navigation, Controls } from '../index';
export default defineComponent({
  name: 'Header',
  components: {
    Logo,
    Navigation,
    Controls
  },
  props: {
    msg: String
  }
});