import { computed, defineComponent, toRefs } from 'vue';
import { SubTitle } from '@/components/Title';
import Sticky from '@/components/Sticky.vue';
import Navigator from './Navigator.vue';
export default defineComponent({
  name: 'ObTOC',
  components: {
    SubTitle,
    Sticky,
    Navigator
  },
  props: {
    toc: String
  },
  setup(props) {
    const tocData = toRefs(props).toc;
    return {
      tocData,
      showToc: computed(() => {
        return tocData !== undefined && tocData.value === '' ? false : true;
      }),
      sideBoxStyle: computed(() => {
        return {
          maxHeight: `${window.innerHeight - 64 - 64 - 52 - 74}px`,
          overflowY: 'scroll',
          overflowX: 'hidden'
        };
      })
    };
  }
});