import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_svg_icon = _resolveComponent("svg-icon");
  return _openBlock(), _createElementBlock("p", {
    id: _ctx.id,
    class: "relative opacity-90 flex items-center pt-12 pb-2 mb-8 text-3xl text-ob-bright uppercase"
  }, [_ctx.icon ? (_openBlock(), _createBlock(_component_svg_icon, {
    key: 0,
    "icon-class": _ctx.icon,
    class: "inline-block mr-2"
  }, null, 8 /* PROPS */, ["icon-class"])) : _createCommentVNode("v-if", true), _createTextVNode(" " + _toDisplayString(_ctx.t(_ctx.titleStr)) + " ", 1 /* TEXT */), _createElementVNode("span", {
    class: "absolute bottom-0 h-1 w-24 rounded-full",
    style: _normalizeStyle(_ctx.gradientBackground)
  }, null, 4 /* STYLE */)], 8 /* PROPS */, _hoisted_1);
}